import React, { useMemo, useState } from 'react'
import { 
  Box, 
  IconButton, 
  Tooltip, 
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Button,
  Badge,
  TextField,
} from '@mui/material'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import SaveIcon from '@mui/icons-material/Save'
import { SavedTemplateDoc } from 'hooks/useManageUsers'
import { UserInfoType } from 'core/types/users'

interface ColumnTemplateSelectorProps {
  activeTemplate: string | null
  setActiveTemplate: (templateId: string) => void
  optimisticDefaultView?: string
  isAdminUser: boolean
  columnTemplates?: SavedTemplateDoc[]
  presetTemplates?: Omit<SavedTemplateDoc, 'createdAt'>[]
  onUpdateDefaultView: (templateId: string) => void
  onSaveNewTemplate: () => void
  onUpdateCurrentTemplate: () => void
  onEditTemplate: (template: SavedTemplateDoc) => void
  onDeleteTemplate: (templateId: string) => void
  userInfo: UserInfoType | null
  hasColumnChanges?: boolean
}

const formatEmail = (email: string) => {
  return email.split('@')[0]
}

const pulseAnimation = `
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(237, 108, 2, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(237, 108, 2, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(237, 108, 2, 0);
    }
  }
`;

const style = document.createElement('style');
style.textContent = pulseAnimation;
document.head.appendChild(style);

export const ColumnTemplateSelector: React.FC<ColumnTemplateSelectorProps> = ({
  activeTemplate,
  setActiveTemplate,
  optimisticDefaultView,
  isAdminUser,
  columnTemplates = [],
  presetTemplates,
  onUpdateDefaultView,
  onSaveNewTemplate,
  onUpdateCurrentTemplate,
  onEditTemplate,
  onDeleteTemplate,
  userInfo,
  hasColumnChanges = false,
}) => {
  // Menu anchor state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [searchTerm, setSearchTerm] = useState('')

  // Get current template name
  const currentTemplate = useMemo(() => {
    const allTemplates = [...(presetTemplates || []), ...(columnTemplates || [])]
    return allTemplates.find(t => t.id === activeTemplate)
  }, [activeTemplate, columnTemplates, presetTemplates])

  // Group and filter templates
  const groupedTemplates = useMemo(() => {
    const filtered = [...(presetTemplates || []), ...(columnTemplates || [])].filter(template => {
      if (!searchTerm) return true
      return (
        template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        template.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        template.createdBy?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    })

    return {
      preset: filtered.filter(t => presetTemplates?.some(pt => pt.id === t.id)),
      myTemplates: filtered.filter(t => t.createdBy === userInfo?.email),
      otherUsers: filtered.filter(t => 
        t.createdBy && 
        t.createdBy !== userInfo?.email && 
        t.createdBy !== "System"
      )
    }
  }, [presetTemplates, columnTemplates, searchTerm, userInfo])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {/* Main Button */}
      <Button
        size="small"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        startIcon={<ViewColumnIcon />}
        sx={{ 
          textTransform: 'none',
          minWidth: '200px',
          position: 'relative',
          pr: optimisticDefaultView === activeTemplate ? 4 : 2,
        }}
      >
        {currentTemplate?.name || 'Select Template'}
        {optimisticDefaultView === activeTemplate && (
          <StarIcon 
            sx={{ 
              color: 'warning.main', 
              fontSize: '1rem',
              position: 'absolute',
              right: 8,
            }} 
          />
        )}
      </Button>

      {/* Quick Actions - Show both buttons when there are changes */}
      {hasColumnChanges && (
        <Box sx={{ display: 'flex', gap: 1 }}>
          {/* Update button - only for custom templates */}
          {currentTemplate && !presetTemplates?.some(t => t.id === currentTemplate.id) && (
            <Tooltip title="Save Changes to Current Template">
              <Button
                size="small"
                variant="contained"
                color="warning"
                onClick={onUpdateCurrentTemplate}
                startIcon={<SaveIcon />}
                sx={{ 
                  textTransform: 'none',
                  animation: 'pulse 2s infinite'
                }}
              >
                Update Template
              </Button>
            </Tooltip>
          )}
          
          {/* Save as New Template button */}
          <Tooltip title="Save Current Layout as New Template">
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={onSaveNewTemplate}
              startIcon={<ContentCopyIcon />}
              sx={{ textTransform: 'none' }}
            >
              Save as New
            </Button>
          </Tooltip>
        </Box>
      )}

      {/* Template Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          sx: { 
            maxWidth: 350,
            maxHeight: 500,
          }
        }}
        autoFocus={false}
        disableAutoFocusItem
      >
        {[
          // Search Box
          <Box 
            key="search"
            sx={{ p: 1, display: 'flex', gap: 1 }}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <TextField
              size="small"
              placeholder="Search templates..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
              }}
              sx={{ flex: 1 }}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <Tooltip title="Create New Template">
              <IconButton 
                size="small" 
                onClick={(e) => {
                  e.stopPropagation()
                  onSaveNewTemplate()
                }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>,

          <Divider key="divider-1" />,

          // Preset Templates Header
          <MenuItem key="preset-header" sx={{ py: 1, px: 2 }}>
            <ListItemText 
              primary="Preset Templates" 
              secondary={`${groupedTemplates.preset.length} templates`}
            />
          </MenuItem>,

          // Preset Templates Items
          ...groupedTemplates.preset.map(template => (
            <MenuItem
              key={`preset-${template.id}`}
              selected={template.id === activeTemplate}
              onClick={() => {
                setActiveTemplate(template.id!)
                setAnchorEl(null)
              }}
              sx={{ 
                py: 1,
                pl: 3,
                minHeight: '48px',
                '& .MuiListItemText-root': {
                  my: 0
                }
              }}
            >
              <ListItemText
                primary={
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    position: 'relative',
                    pr: optimisticDefaultView === template.id ? 2 : 0,
                  }}>
                    {template.name}
                    {optimisticDefaultView === template.id && (
                      <StarIcon 
                        sx={{ 
                          color: 'warning.main', 
                          fontSize: '1rem',
                          position: 'absolute',
                          right: 0,
                        }} 
                      />
                    )}
                  </Box>
                }
              />
              <Box sx={{ ml: 1, display: 'flex', gap: 0.5 }}>
                <Tooltip title="Set as Default">
                  <IconButton 
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      onUpdateDefaultView(template.id!)
                    }}
                  >
                    {optimisticDefaultView === template.id ? 
                      <StarIcon fontSize="small" sx={{ color: 'warning.main' }} /> : 
                      <StarBorderIcon fontSize="small" />
                    }
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create Copy">
                  <IconButton 
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      onSaveNewTemplate()
                    }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </MenuItem>
          )),

          // My Templates Section (conditional)
          ...(groupedTemplates.myTemplates.length > 0 ? [
            <Divider key="divider-2" />,
            <MenuItem key="my-templates-header" sx={{ py: 1, px: 2 }}>
              <ListItemText 
                primary="My Templates" 
                secondary={`${groupedTemplates.myTemplates.length} templates`}
              />
            </MenuItem>,
            ...groupedTemplates.myTemplates.map(template => (
              <MenuItem
                key={`my-${template.id}`}
                selected={template.id === activeTemplate}
                onClick={() => {
                  setActiveTemplate(template.id!)
                  setAnchorEl(null)
                }}
                sx={{ 
                  py: 1,
                  pl: 3,
                  minHeight: '48px',
                  '& .MuiListItemText-root': {
                    my: 0
                  }
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      position: 'relative',
                      pr: optimisticDefaultView === template.id ? 2 : 0,
                    }}>
                      {template.name}
                      {optimisticDefaultView === template.id && (
                        <StarIcon 
                          sx={{ 
                            color: 'warning.main', 
                            fontSize: '1rem',
                            position: 'absolute',
                            right: 0,
                          }} 
                        />
                      )}
                    </Box>
                  }
                  secondary={template.description}
                />
                <Box sx={{ ml: 1, display: 'flex', gap: 0.5 }}>
                  <Tooltip title="Set as Default">
                    <IconButton 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        onUpdateDefaultView(template.id!)
                      }}
                    >
                      {optimisticDefaultView === template.id ? 
                        <StarIcon fontSize="small" sx={{ color: 'warning.main' }} /> : 
                        <StarBorderIcon fontSize="small" />
                      }
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        //@ts-ignore
                        onEditTemplate(template)
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        onDeleteTemplate(template.id!)
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </MenuItem>
            ))
          ] : []),

          ...(groupedTemplates.otherUsers.length > 0 ? [
            <Divider key="divider-3" />,
            <MenuItem key="other-users-header" sx={{ py: 1, px: 2 }}>
              <ListItemText 
                primary="Other Users' Templates" 
                secondary={`${groupedTemplates.otherUsers.length} templates`}
              />
            </MenuItem>,
            ...groupedTemplates.otherUsers.map(template => (
              <MenuItem
                key={`other-${template.id}`}
                selected={template.id === activeTemplate}
                onClick={() => {
                  setActiveTemplate(template.id!)
                  setAnchorEl(null)
                }}
                sx={{ 
                  py: 1,
                  pl: 3,
                  minHeight: template.description ? '64px' : '48px',
                  '& .MuiListItemText-root': {
                    my: 0
                  },
                  '& .MuiTypography-caption': {
                    mt: 0.5,
                    display: 'block',
                    color: 'text.secondary'
                  }
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      position: 'relative',
                      pr: optimisticDefaultView === template.id ? 2 : 0,
                    }}>
                      {template.name}
                      {optimisticDefaultView === template.id && (
                        <StarIcon 
                          sx={{ 
                            color: 'warning.main', 
                            fontSize: '1rem',
                            position: 'absolute',
                            right: 0,
                          }} 
                        />
                      )}
                    </Box>
                  }
                  secondary={
                    <>
                      {template.description}
                      <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
                        By: {formatEmail(template.createdBy || 'Unknown')}
                      </Typography>
                    </>
                  }
                />
                <Box sx={{ ml: 1, display: 'flex', gap: 0.5 }}>
                  <Tooltip title="Set as Default">
                    <IconButton 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        onUpdateDefaultView(template.id!)
                      }}
                    >
                      {optimisticDefaultView === template.id ? 
                        <StarIcon fontSize="small" sx={{ color: 'warning.main' }} /> : 
                        <StarBorderIcon fontSize="small" />
                      }
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Create Copy">
                    <IconButton 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        onSaveNewTemplate()
                      }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </MenuItem>
            ))
          ] : [])
        ]}
      </Menu>
    </Box>
  )
} 