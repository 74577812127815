import { AttachMoney, Delete, DirectionsCar, Link, LocalGasStation, Phone } from '@mui/icons-material'
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import icon from 'assets/Icon-OpticTruckWorks-02.png'
import { formatDistance, formatPrice } from 'core/utils/inventoryUtils'
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject'
import React from 'react'

interface ImportedVehicleCardProps {
  collectionName: string
  vehicle: Vehicle
  onClick: () => void
  onDeleteClick: () => void
  subCollection?: { name: string; parentDocId: string }
  isAdmin?: boolean
}

const ImportedVehicleCard: React.FC<ImportedVehicleCardProps> = ({
  collectionName,
  subCollection,
  vehicle,
  onClick,
  onDeleteClick,
  isAdmin,
}) => {
  const { data: photos, isLoading: isLoadingPhoto } = usePhotosForInventoryObject(
    collectionName,
    vehicle.id,
    undefined,
    subCollection,
  )

  return (
    <Card sx={{ minWidth: 275, position: 'relative', '&:hover': { boxShadow: 6 } }} onClick={onClick}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: '2px solid',
          borderColor: 'primary.main',
          opacity: 0,
          transition: 'opacity 0.3s',
          '&:hover': { opacity: 1 },
        }}
      />
      <CardHeader
        title={
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6'>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Typography>
            {vehicle.status && (
              <Badge
                color={vehicle.status === 'Available' ? 'success' : 'secondary'}
                badgeContent={vehicle.status}
                sx={{
                  '& .MuiBadge-badge': {
                    marginRight: 4,
                    color: 'white',
                    bgcolor: vehicle.status === 'Available' ? '#2e7d32' : '',
                  },
                }}
              />
            )}
          </Box>
        }
      />
      <Box sx={{ height: 200, flexShrink: 0 }}>
        {isLoadingPhoto ? (
          <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
            <CircularProgress sx={{ mt: 2 }} />
          </Box>
        ) : photos && photos.length > 0 ? (
          <CardMedia
            component='img'
            srcSet={`${photos[0].url}?w=82&h=82&fit=crop&auto=format&dpr=2 2x`}
            src={`${photos[0].url}?w=82&h=82&fit=crop&auto=format`}
            alt={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
            loading='lazy'
            sx={{
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100%'
            sx={{ bgcolor: 'background.default' }}
          >
            <img
              src={icon}
              alt='No Photo Available'
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'contain',
              }}
            />
          </Box>
        )}
      </Box>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
              <DirectionsCar fontSize='small' /> Vehicle Info
            </Typography>
            <Typography variant='body2'>
              <strong>VIN:</strong> {vehicle.vin}
            </Typography>
            <Typography variant='body2'>
              <strong>Type:</strong> {vehicle.type}
            </Typography>
            <Typography variant='body2'>
              <strong>Size:</strong> {vehicle.size}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
              <LocalGasStation fontSize='small' /> Performance
            </Typography>
            <Typography variant='body2'>
              <strong>Fuel:</strong> {vehicle.fuel}
            </Typography>
            <Typography variant='body2'>
              <strong>Miles:</strong> {formatDistance(vehicle.miles)}
            </Typography>
          </Grid>
          {isAdmin && (
            <Grid item xs={6}>
              <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
                <Phone fontSize='small' /> Contact
              </Typography>
              <Typography variant='body2'>
                <strong>Contact:</strong> {vehicle.who}
              </Typography>
              <Typography variant='body2'>
                <strong>Location:</strong> {vehicle.location}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography variant='subtitle1' display='flex' alignItems='center' gap={1}>
              <AttachMoney fontSize='small' /> Pricing
            </Typography>
            {isAdmin && (
              <Typography variant='body2'>
                <strong>Buy Price:</strong> {formatPrice(vehicle.seller_asking_price)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
          <IconButton
            aria-label='Delete Vehicle'
            onClick={event => {
              event.stopPropagation()
              console.log('click delete')
              onDeleteClick()
            }}
          >
            <Delete />
          </IconButton>
          {vehicle.link && (
            <Button
              variant='text'
              startIcon={<Link />}
              onClick={event => {
                event.stopPropagation()
                window.open(vehicle.link, '_blank')
              }}
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              Go to website
            </Button>
          )}
        </Box>
      </CardActions>
    </Card>
  )
}

export default ImportedVehicleCard
