import { functionUrls } from 'core/api'

export interface ThumbnailResponse {
  mimeType: string
  base64Image: string
}


export async function getThumbnails(targetUrls: string[]): Promise<(ThumbnailResponse | null)[]> {
  try {
    const response = await fetch(
      `${functionUrls.fetchThumbnail}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ urls: targetUrls }),
      }
    )

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.error('Error fetching thumbnail:', error)
    return []
  }
}
