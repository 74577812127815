  import React, {
    useCallback,
    useMemo,
    useRef,
    useEffect,
    useState,
    RefObject,
    FC,
  } from 'react';
  import { AgGridReact } from 'ag-grid-react';
  import 'ag-grid-enterprise';
  import {
    GridReadyEvent,
    GridApi,
    FirstDataRenderedEvent,
  } from 'ag-grid-enterprise';
  import { Badge, useTheme, Box, Button, CircularProgress, Typography } from '@mui/material';
  import { useInventory } from 'hooks/useInventory';
  import { useAuth } from 'contexts/AuthContext';
  import { VehiclePhotosRenderer } from './VehiclePhotosRenderer';
  import { getInventoryColumns, formatCurrency } from './getInventoryColumns';
  import { LoadingInventory } from '../LoadingInventory';
  import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
  import { defaultColDef } from './constants';
  import { ActionsPanel } from './ActionsPanel';
  import { useLocalStorageState } from '@toolpad/core';
  import { SaveTemplateDialog } from './SaveTemplateDialog';
  import { useManageUsers } from 'hooks/useManageUsers';
  import { useSnackbar } from 'contexts/snackBarContext';
  import { updateUser } from 'core/api/users';
  import { useMutation, useQueryClient } from '@tanstack/react-query';
  import { ColumnTemplateSelector } from './ColumnTemplateSelector';
  import { getGridConfig } from './getGridConfig';
  import QuickFilterInput from './QuickFilterInput';
  import { useGridZoom } from './hooks/useGridZoom';
  import { SavedTemplateDoc } from 'hooks/useManageUsers';
  import { COLUMN_TEMPLATES } from './columnTemplates';
  import ZoomControls from './ZoomControls';

  type GridTheme = {
    name: string;
    rowHeight: number;
    fontSize: string;
  };

  interface AgGridInventoryProps {
    gridRef: RefObject<AgGridReact>;
    gridTheme: GridTheme;
    rows: Vehicle[];
    isLoading?: boolean;
    inventoryCollection: string;
  }

  const ZoomContext = React.createContext<{
    fontSize: string;
    rowHeight: number;
  }>({ fontSize: '14px', rowHeight: 32 });

  const AgGridInventory: FC<AgGridInventoryProps> = ({
    gridRef,
    gridTheme,
    rows,
    isLoading = false,
    inventoryCollection,
  }) => {
    const { userInfo, setUserInfo } = useAuth();
    const theme = useTheme();
    const gridApiRef = useRef<GridApi | null>(null);
    const isComponentUnmounted = useRef(false);

    const { handleCellValueChange } = useInventory(inventoryCollection);

    const [selectedRows, setSelectedRows] = useState<Vehicle[]>([]);
    const [activeToolPanel, setActiveToolPanel] = useState<string | undefined>(
      undefined,
    );
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [quickFilterText, setQuickFilterText] = useState('');
    const [activeTemplate, setActiveTemplate] = useLocalStorageState<string>(
      'inventory-column-template',
      userInfo?.default_view || 'default',
    );
    const [saveTemplateDialogOpen, setSaveTemplateDialogOpen] = useState(false);
    const [hasActiveFilters, setHasActiveFilters] = useState(false);
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [templateToUpdate, setTemplateToUpdate] =
      useState<SavedTemplateDoc | null>(null);
    const {
      columnTemplates,
      saveTemplate,
      deleteTemplate,
      updateTemplate,
      isLoadingTemplates,
    } = useManageUsers();
    const isAdminUser =
      userInfo?.user_type === 'admin' || userInfo?.user_type === 'babyAdmin';

    const { showSnackbar } = useSnackbar();
    const initialLoadComplete = useRef(false);
    const queryClient = useQueryClient();
    const [optimisticDefaultView, setOptimisticDefaultView] = useState<
      string | undefined
    >(userInfo?.default_view);
    const [isSellPage, setIsSellPage] = useState(false);
    const isApplyingTemplate = useRef(false);

    const [isApplyingTemplateLoading, setIsApplyingTemplateLoading] = useState(false);

    useEffect(() => {
      if (!initialLoadComplete.current && userInfo) {
                initialLoadComplete.current = true;
        setIsInitialLoad(false);
      }
    }, [userInfo]);

    useEffect(() => {
      if (!gridApiRef.current || gridApiRef.current.isDestroyed()) return;
      
      const api = gridApiRef.current;

      const checkFilters = () => {
        if (!api || api.isDestroyed()) return;

        const hasColumnFilters = api.isAnyFilterPresent();
        const hasQuickFilter = !!api.getQuickFilter() || !!quickFilterText;
        const hasRowGrouping = (api.getRowGroupColumns()?.length || 0) > 0;
        const hasPivotColumns = (api.getPivotColumns()?.length || 0) > 0;

        const hasAnyFilters =
          hasColumnFilters || hasQuickFilter || hasRowGrouping || hasPivotColumns;
        setHasActiveFilters(hasAnyFilters);
      };

      checkFilters();

      const events = [
        'filterChanged',
        'columnRowGroupChanged',
        'columnPivotChanged',
        'modelUpdated',
      ];

      events.forEach((event) => api.addEventListener(event as any, checkFilters));

      return () => {
        if (!api.isDestroyed()) {
          events.forEach((event) => api.removeEventListener(event as any, checkFilters));
        }
      };
    }, [quickFilterText]);

    const clearAllFilters = useCallback(() => {
      if (gridApiRef.current) {
        setQuickFilterText('');
        gridApiRef.current.setFilterModel(null);
                gridApiRef.current.applyColumnState({
          defaultState: { sort: null },
          applyOrder: true,
        });
        gridApiRef.current.setRowGroupColumns([]);
        gridApiRef.current.setPivotColumns([]);
        gridApiRef.current.setValueColumns([]);

        if (gridApiRef.current.setAdvancedFilterModel) {
          gridApiRef.current.setAdvancedFilterModel(null);
        }

        requestAnimationFrame(() => {
          if (gridApiRef.current) {
            gridApiRef.current.refreshCells({ force: true });
            gridApiRef.current.onFilterChanged();

            const hasAnyFilters =
              gridApiRef.current.isAnyFilterPresent() ||
              (gridApiRef.current.getRowGroupColumns()?.length > 0) ||
              (gridApiRef.current.getPivotColumns()?.length > 0);

            setHasActiveFilters(hasAnyFilters);
          }
        });

        showSnackbar('All filters cleared', 'success');
      }
    }, [showSnackbar]);

    const onSelectionChanged = useCallback(() => {
      const selected = gridApiRef.current?.getSelectedRows() || [];
      setSelectedRows(selected);

      if (gridApiRef.current) {
        if (selected.length > 0 && activeToolPanel !== 'actions') {
          // gridApiRef.current.openToolPanel('actions');
          setActiveToolPanel('actions');
        } else if (selected.length === 0 && activeToolPanel === 'actions') {
          // gridApiRef.current.closeToolPanel();
          setActiveToolPanel(undefined);
        }
      }
    }, [activeToolPanel]);

    const sideBar = useMemo(
      () => ({
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
          {
            id: 'actions',
            labelDefault: 'Actions',
            labelKey: 'actions',
            iconKey: 'menu',
            toolPanel: () => (
              <ActionsPanel
                selectedRows={selectedRows}
                clearAllFilters={clearAllFilters}
              />
            ),
          },
        ],
        defaultToolPanel: activeToolPanel,
      }),
      [selectedRows, clearAllFilters, activeToolPanel],
    );

    const onCellClicked = useCallback((params: any) => {
      if (params.column.getColId() === 'stock_number') return;
      // const columnState = params.api.getColumnState()
      // console.log('columnState', columnState)
            if (params.column?.getColDef().editable !== false) {
        params.api.startEditingCell({
          rowIndex: params.rowIndex!,
          colKey: params.column.getColId(),
        });
      }
    }, []);

    const handleCellValueChanged = useCallback(
      (params: any) => {
        const { data, colDef, newValue } = params;
        if (colDef.field === 'status' && newValue.toLowerCase() === 'sold' && data.show_on_website) {
          handleCellValueChange(data.id, 'show_on_website', false);
        }
        handleCellValueChange(data.id, colDef.field!, newValue);

        if (gridApiRef.current) {
          const allColumnIds: string[] = [];
          gridApiRef.current.getColumns()?.forEach((column) => {
            allColumnIds.push(column.getId());
          });
          gridApiRef.current.autoSizeColumns(allColumnIds);
        }
      },
      [handleCellValueChange],
    );

    const isAdmin = userInfo?.user_type === 'admin';
    const columnDefs = useMemo(() => {
            const inventoryColumns = getInventoryColumns(isAdmin, theme);
      const allTemplates = [...(columnTemplates || [])];
      const template =
        allTemplates.find((t) => t.id === activeTemplate) || allTemplates[0];
            return inventoryColumns.map((col) => ({
        ...col,
        hide: !template?.columnState.some((state) => state.colId === col.colId),
      }));
    }, [isAdmin, activeTemplate, columnTemplates, theme]);

    const { fontSize, rowHeight, updateZoom } = useGridZoom(gridApiRef);

    const zoomContextValue = useMemo(
      () => ({
        fontSize,
        rowHeight,
      }),
      [fontSize, rowHeight],
    );

    const onGridReady = useCallback(
      (params: GridReadyEvent) => {
                const api = params.api;
        gridApiRef.current = api;

        if (!api.isDestroyed()) {
          api.forEachNode((node) => {
            node.setExpanded(false);
          });
        }
      },
      [],
    );

    const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
            const api = params.api;
      if (!api.isDestroyed()) {
        requestAnimationFrame(() => {
          const presetTemplate = COLUMN_TEMPLATES.find(t => t.id === activeTemplate);
          const customTemplate = columnTemplates?.find(t => t.id === activeTemplate);
          const template = presetTemplate || customTemplate;

          if (template) {
                        api.applyColumnState({
              state: template.columnState,
              applyOrder: true,
            });
          }
        });
      }
    }, [activeTemplate, columnTemplates]);

    const masterDetail = useMemo(
      () => ({
        masterDetail: true,
        detailRowHeight: 400,
        detailCellRenderer: VehiclePhotosRenderer,
        detailRowAutoHeight: true,
        isRowMaster: () => true,
        suppressRowDetailRefresh: true,
        isDetailDisplayed: () => false,
        suppressExpandablePivotGroups: true,
        groupDefaultExpanded: 0,
        defaultExpanded: false,
      }),
      [],
    );

    const onExportClick = useCallback(() => {
      if (!gridApiRef.current) return;

      const params = {
        fileName: `Inventory_Export_${
          new Date().toISOString().split('T')[0]
        }.xlsx`,
        processCellCallback: (params: any) => {
          if (params.column.colId === 'profit') {
            return params.value ? formatCurrency(params.value) : '';
          }
          return params.value;
        },
        processHeaderCallback: (params: any) =>
          params.column.getColDef().headerName,
      };

      gridApiRef.current.exportDataAsExcel(params);
    }, []);

    const [hasColumnChanges, setHasColumnChanges] = useState(false);

    useEffect(() => {
            setHasColumnChanges(false);
    }, [activeTemplate]);

    const handleColumnChanged = useCallback((event: any) => {
            if (!event.source.startsWith("ui") && event.source !== "toolPanelUi") return;
      setHasColumnChanges(true);
    }, []);

    const filteredRows = useMemo(() => {
      if (isLoading) return [];
      return isSellPage 
        ? rows.filter(row => row.status?.toLowerCase() === 'sold' || row.status?.toLowerCase() === 'sold elsewhere')  // Only show SOLD vehicles
        : rows.filter(row => row.status?.toLowerCase() !== 'sold' && row.status?.toLowerCase() !== 'sold elsewhere'); // Show non-sold vehicles
    }, [rows, isSellPage, isLoading]);

    const gridConfig = useMemo(
      () =>
        getGridConfig({
          columnDefs,
          rows: filteredRows,
          isLoading,
          defaultColDef,
          masterDetail,
          onCellClicked,
          handleCellValueChanged,
          sideBar,
          rowHeight,
          fontSize,
          onGridReady,
          onSelectionChanged,
          setActiveToolPanel,
          onFirstDataRendered,
          onColumnMoved: handleColumnChanged,
          onColumnVisible: handleColumnChanged,
          onColumnResized: handleColumnChanged,
          onColumnPinned: handleColumnChanged,
          onColumnValueChanged: handleColumnChanged,
          onColumnPivotChanged: handleColumnChanged,
          onColumnRowGroupChanged: handleColumnChanged,
          isSellPage,
          onToggleSoldPage: setIsSellPage,
        }),
      [
        columnDefs,
        filteredRows,
        isLoading,
        sideBar,
        onCellClicked,
        handleCellValueChanged,
        masterDetail,
        onGridReady,
        onSelectionChanged,
        rowHeight,
        fontSize,
        setActiveToolPanel,
        onFirstDataRendered,
        handleColumnChanged,
        isSellPage,
        setIsSellPage,
      ],
    );

    const handleSaveCurrentTemplate = async (
      name: string,
      description: string,
      setAsDefault?: boolean,
    ) => {
            const columnState = gridApiRef.current?.getColumnState();

      const template: Omit<SavedTemplateDoc, 'id' | 'createdBy' | 'createdAt'> = {
        name,
        description: description || undefined,
        columnState: columnState || [],
      };

      try {
        const newTemplateId = await new Promise<string>((resolve, reject) => {
          saveTemplate(template, {
            onSuccess: (id) => resolve(id),
            onError: (error) => reject(error),
          });
        });

        if (setAsDefault && newTemplateId) {
          await updateDefaultViewMutation.mutateAsync(newTemplateId);
        }

        await queryClient.invalidateQueries({ queryKey: ['columnTemplates'] });

        setActiveTemplate(newTemplateId);

        setSaveTemplateDialogOpen(false);
      } catch (error) {
        console.error('Error saving template:', error);
        showSnackbar('Error saving template', 'error');
      }
    };

    const handleUpdateTemplate = async (
      name: string,
      description: string,
      setAsDefault?: boolean,
    ) => {
            const columnState = gridApiRef.current?.getColumnState();

      if (!templateToUpdate?.id) {
        showSnackbar('No template selected to update', 'error');
        return;
      }

      const updatedTemplate: Partial<SavedTemplateDoc> = {
        name,
        description: description || undefined,
        columnState: columnState || [],
      };

      try {
        await updateTemplate({
          templateId: templateToUpdate.id,
          template: updatedTemplate,
        });

        if (setAsDefault) {
          await updateDefaultViewMutation.mutateAsync(templateToUpdate.id);
        }

        await queryClient.invalidateQueries({ queryKey: ['columnTemplates'] });

        setActiveTemplate(templateToUpdate.id);

        setIsUpdateMode(false);
        setTemplateToUpdate(null);
      } catch (error) {
        console.error('Error updating template:', error);
        showSnackbar('Error updating template', 'error');
      }
    };

    const updateDefaultViewMutation = useMutation({
      mutationFn: async (templateId: string) => {
                await updateUser(userInfo?.firestoreDocId!, {
          default_view: templateId,
        });
        return templateId;
      },
      onMutate: async (newTemplateId) => {
                await queryClient.cancelQueries({
          queryKey: ['user', userInfo?.firestoreDocId],
        });

        const previousUser = queryClient.getQueryData([
          'user',
          userInfo?.firestoreDocId,
        ]);
        const previousDefaultView = optimisticDefaultView;

        setOptimisticDefaultView(newTemplateId);

        return { previousUser, previousDefaultView, previousUserInfo: userInfo };
      },
      onError: (err, newTemplateId, context) => {
        setOptimisticDefaultView(context?.previousDefaultView);
        setActiveTemplate(context?.previousDefaultView || 'default');
        if (context?.previousUserInfo) {
          setUserInfo(context.previousUserInfo);
        }
        queryClient.setQueryData(
          ['user', userInfo?.firestoreDocId],
          context?.previousUser,
        );
        showSnackbar('Error updating default view', 'error');
      },
      onSuccess: async (templateId) => {
        showSnackbar('Default view updated', 'success');
                if (userInfo) {
          setUserInfo({
            ...userInfo,
            default_view: templateId,
          });
        }

      },
    });

    const handleUpdateCurrentTemplate = () => {
            if (currentTemplate) {
                setTemplateToUpdate(currentTemplate);
        setIsUpdateMode(true);
        setSaveTemplateDialogOpen(true);
      } else {
        showSnackbar('No template selected to update', 'error');
      }
    };

    const [currentTemplate, setCurrentTemplate] =
      useState<SavedTemplateDoc | null>(null);

    useEffect(() => {
            const allTemplates = [...(columnTemplates || [])];
      const template = allTemplates.find((t) => t.id === activeTemplate);
            setCurrentTemplate(template || null);
    }, [activeTemplate, columnTemplates]);
    useEffect(() => {
            return () => {
        isComponentUnmounted.current = true;
        if (gridApiRef.current) {
          gridApiRef.current = null;
        }
      };
    }, []);

    const handleTemplateChange = useCallback(
      (templateId: string) => {

        if (!gridApiRef.current || gridApiRef.current.isDestroyed()) return;
        setHasColumnChanges(false);
                const presetTemplate = COLUMN_TEMPLATES.find(t => t.id === templateId);
        const customTemplate = columnTemplates?.find(t => t.id === templateId);
        const template = presetTemplate || customTemplate;

        if (template) {
                    setTimeout(() => {
          if (gridApiRef.current) {
            gridApiRef.current.applyColumnState({
              state: template.columnState,
              applyOrder: true,
            });      
          }
        }, 0)
      }
      },
      [columnTemplates],
    );

    useEffect(() => {
      if (!isInitialLoad) {
                handleTemplateChange(activeTemplate!);
      }
    }, [activeTemplate, handleTemplateChange]);

    useEffect(() => {
      if (!isInitialLoad && userInfo?.default_view && !isApplyingTemplate.current) {
        isApplyingTemplate.current = true;
        setIsApplyingTemplateLoading(true);
        
        requestAnimationFrame(() => {
                    setActiveTemplate(userInfo.default_view || 'default');
          
          requestAnimationFrame(() => {
            isApplyingTemplate.current = false;
            setIsApplyingTemplateLoading(false); 
          });
        });
      }
    }, [userInfo?.default_view, isInitialLoad]);

    const showLoading = !initialLoadComplete.current || isLoadingTemplates;

    const onZoomIn = useCallback(() => {
      updateZoom(1.1);
    }, [updateZoom]);

    const onZoomOut = useCallback(() => {
      updateZoom(0.9);
    }, [updateZoom]);

    return (
      <Box>
        {showLoading ? (
          <LoadingInventory />
        ) : (
          <>
            {isApplyingTemplateLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  zIndex: 9999,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backdropFilter: 'blur(2px)',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <CircularProgress />
                  <Typography variant="body1">Applying template...</Typography>
                </Box>
              </Box>
            )}
            {/* Main Header */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                mb: 2,
                backgroundColor: 'background.paper',
                borderRadius: 1,
                p: 1.5,
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              }}
            >
              {/* Left Side - Template Controls */}
              <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
                <ColumnTemplateSelector
                  activeTemplate={activeTemplate}
                  setActiveTemplate={setActiveTemplate}
                  optimisticDefaultView={optimisticDefaultView}
                  isAdminUser={isAdminUser}
                  columnTemplates={columnTemplates}
                  presetTemplates={COLUMN_TEMPLATES}
                  onUpdateDefaultView={(templateId) =>
                    updateDefaultViewMutation.mutate(templateId)
                  }
                  onSaveNewTemplate={() => {
                    setIsUpdateMode(false);
                    setSaveTemplateDialogOpen(true);
                  }}
                  onUpdateCurrentTemplate={handleUpdateCurrentTemplate}
                  onEditTemplate={(template) => {
                    setTemplateToUpdate(template);
                    setIsUpdateMode(true);
                    setSaveTemplateDialogOpen(true);
                  }}
                  onDeleteTemplate={(templateId) => deleteTemplate(templateId)}
                  userInfo={userInfo}
                  hasColumnChanges={hasColumnChanges}
                />
              </Box>

              {/* Center - Quick Filter */}
              <Box sx={{ width: '300px' }}>
                <QuickFilterInput
                  value={quickFilterText}
                  onChange={setQuickFilterText}
                  gridApi={gridApiRef}
                />
              </Box>

              {/* Right Side - Actions */}
              <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <ZoomControls onZoomIn={onZoomIn} onZoomOut={onZoomOut} />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={clearAllFilters}
                  startIcon={
                    <Badge
                      variant="dot"
                      color="error"
                      invisible={!hasActiveFilters}
                      sx={{
                        '& .MuiBadge-dot': {
                          backgroundColor: theme.palette.error.main,
                        },
                      }}
                    >
                      <FilterAltOffIcon />
                    </Badge>
                  }
                  sx={{
                    borderColor: hasActiveFilters
                      ? theme.palette.error.main
                      : 'divider',
                    color: hasActiveFilters
                      ? theme.palette.error.main
                      : 'text.secondary',
                    '&:hover': {
                      backgroundColor: hasActiveFilters
                        ? 'error.lighter'
                        : 'action.hover',
                      borderColor: hasActiveFilters
                        ? theme.palette.error.main
                        : 'divider',
                    },
                  }}
                >
                  Clear Filters
                </Button>
              </Box>
            </Box>

            {/* Grid */}
            <ZoomContext.Provider value={zoomContextValue}>
              <Box
                sx={{
                  backgroundColor: 'background.paper',
                  borderRadius: 1,
                  overflow: 'hidden',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                }}
              >
                <div
                  className={
                    theme.palette.mode === 'dark'
                      ? 'ag-theme-alpine-dark'
                      : 'ag-theme-alpine'
                  }
                  style={{
                    width: '100%',
                    height: 'calc(98vh - 190px)',
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    {...gridConfig}
                    quickFilterText={quickFilterText}
                  />
                </div>
              </Box>
            </ZoomContext.Provider>

            <SaveTemplateDialog
              open={saveTemplateDialogOpen}
              onClose={() => {
                setSaveTemplateDialogOpen(false);
                setIsUpdateMode(false);
                setTemplateToUpdate(null);
              }}
              onSave={handleSaveCurrentTemplate}
              onUpdate={handleUpdateTemplate}
              isUpdate={isUpdateMode}
              currentTemplate={
                templateToUpdate
                  ? {
                      name: templateToUpdate.name,
                      description: templateToUpdate.description,
                    }
                  : undefined
              }
              visibleColumns={
                gridApiRef.current
                  ?.getAllGridColumns()
                  ?.filter((col) => col?.isVisible())
                  ?.map((col) => col?.getColId())
                  ?.filter(Boolean) || []
              }
              baseTemplate={currentTemplate?.name || 'Default'}
              isCurrentDefault={optimisticDefaultView === templateToUpdate?.id}
            />
          </>
        )}
      </Box>
    );
  };

  export default React.memo(AgGridInventory);


