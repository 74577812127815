import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'contexts/snackBarContext'
import { readInventoryFromXLSX } from 'core/api/inventory/handleInventoryCsvUpload'
import { useAddFirestoreDocument } from 'hooks/firebase/useAddFirestoreDocument'
import TruckIcon from '@mui/icons-material/LocalShipping'
import SpeedIcon from '@mui/icons-material/Speed'
import { useAuth } from 'contexts/AuthContext'
import { generateOpticStockNumber, generateShieldStockNumber } from 'core/api/inventory/generateStockNumber'
import { useQueryFirestoreDocuments } from 'hooks/firebase/useQueryFirestoreDocuments'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { addVinData } from 'views/SuperAdmin/Tools/vehicleUtils'
import { useNavigate, useLocation } from 'react-router-dom'
import { updateSubmissionStatus } from 'core/api/submission/updateSubmissionStatus'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import InventoryIcon from '@mui/icons-material/Inventory'
import { db } from 'core/config/firebase'
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore'

interface FleetOwner {
  name: string
  email: string
  phone: string
}

interface SubmissionVehicle {
  id: string
  year: string
  make: string
  model: string
  vin: string
  miles: string
  type: string
  size: string
  user_condition: string
  lien: string
  financingBank: string
  submissionId: string
  desiredPrice: string
  damage?: string
  stock_number?: string
  location?: string
  fuel?: string
}

interface SubmittedVehicleInfo {
  vin: string;
  inventory: 'shield' | 'optic';
}

const formatMileage = (miles: string | number | undefined): string => {
  if (!miles) return '0'
  const mileageString = miles.toString()
  return mileageString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const formatField = (value: string | number | undefined): string => {
  if (!value || value === '') return 'N/A'
  return value.toString()
}

const FleetListPage: React.FC = () => {
  const location = useLocation()
  const { fleetOwner, submissionId, file } = location.state || {}
  const navigate = useNavigate()
  const [selectedVehicles, setSelectedVehicles] = useState<SubmissionVehicle[]>([])
  const [vehicles1, setVehicles1] = useState<SubmissionVehicle[]>([])
  const { showSnackbar } = useSnackbar()
  const { userInfo } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [submittedVehicles, setSubmittedVehicles] = useState<SubmittedVehicleInfo[]>([])

  const { data: opticVehicles } = useQueryFirestoreDocuments({
    inventoryCollectionName: 'master_inventory',
  })

  const { data: shieldVehicles } = useQueryFirestoreDocuments({
    inventoryCollectionName: 'shield_inventory',
  })

  const { mutate: addFirebaseDocumentToOpticInventory } = useAddFirestoreDocument({
    collectionName: 'master_inventory',
  })

  const { mutate: addFirebaseDocumentToShieldInventory } = useAddFirestoreDocument({
    collectionName: 'shield_inventory',
  })

  const { data: spreadsheetVehicles, isLoading: isSpreadsheetLoading } = useQuery(
    ['submission-vehicles', file],
    async () => {
      if (!file) return null
      const vehicles = await readInventoryFromXLSX(file)
      const validVehicles = vehicles.filter(vehicle => vehicle.vin.length === 17)
      const updatedVehicles = await Promise.all(validVehicles.map(addVinData))
      return updatedVehicles.map((vehicle, index) => ({
        id: `spreadsheet_${index}`,
        year: vehicle.year || '',
        make: vehicle.make || '',
        model: vehicle.model || '',
        vin: vehicle.vin || '',
        miles: vehicle.miles || '',
        type: vehicle.type || '',
        size: vehicle.size || '',
        user_condition: '',
        lien: '',
        financingBank: '',
        submissionId: '',
        desiredPrice: '',
      })) as SubmissionVehicle[]
    },
    {
      staleTime: Infinity,
      enabled: !!file,
    },
  )

  // Query to get vehicles from form_sell collection
  const fetchVehiclesFromFormSell = async () => {
    try {
      const formSellRef = doc(db, 'form_sell', submissionId)
      const docSnap = await getDoc(formSellRef)
      
      if (!docSnap.exists()) {
        console.error('No submission found')
        return []
      }

      // Get submitted VINs from the document
      const submittedVehiclesFromDoc = docSnap.data().submittedVehicles || []
      setSubmittedVehicles(submittedVehiclesFromDoc)

      // Extract vehicles array from the submission document
      const vehicles = docSnap.data().vehicles || []
      
      return vehicles.map((vehicle: any, index: number) => ({
        id: `${submissionId}_${index}`,
        year: vehicle.year || '',
        make: vehicle.make || '',
        model: vehicle.model || '',
        vin: vehicle.vin || '',
        miles: vehicle.miles || '0',
        type: vehicle.type || vehicle.truckType || '',
        size: vehicle.size || '',
        user_condition: vehicle.user_condition || '',
        lien: vehicle.lien || '',
        location: vehicle.location || '',
        damage: vehicle.damage || '',
        fuel: vehicle.fuel || '',
        financingBank: vehicle.financingBank || '',
        submissionId: submissionId,
        desiredPrice: vehicle.desiredPrice || ''
      })) as SubmissionVehicle[]

    } catch (error) {
      console.error('Error fetching vehicles:', error)
      showSnackbar('Error fetching vehicles', 'error')
      return []
    }
  }

  useEffect(() => {
    const loadVehicles = async () => {
      setIsLoading(true)
      try {
        if (file) {
          // Handle spreadsheet data
          if (spreadsheetVehicles) {
            setVehicles1(spreadsheetVehicles)
          }
        } else if (submissionId) {
          // Handle form_sell data
          const formSellVehicles = await fetchVehiclesFromFormSell()
          setVehicles1(formSellVehicles)
        }
      } catch (error) {
        console.error('Error loading vehicles:', error)
        showSnackbar('Error loading vehicles', 'error')
      } finally {
        setIsLoading(false)
      }
    }
    
    loadVehicles()
  }, [submissionId, file, spreadsheetVehicles])

  const removeVehicleFromFormSell = async (vehicleId: string) => {
    try {
      const vehicleRef = doc(db, 'form_sell', vehicleId)
      await deleteDoc(vehicleRef)
    } catch (error) {
      console.error('Error removing vehicle from form_sell:', error)
      showSnackbar('Error removing vehicle from form_sell', 'error')
    }
  }

  const updateFormSellDocument = async (remainingVehicles: SubmissionVehicle[]) => {
    try {
      const formSellRef = doc(db, 'form_sell', submissionId)
      const docSnap = await getDoc(formSellRef)
      
      if (!docSnap.exists()) {
        console.error('No submission found')
        return
      }

      const data = docSnap.data()
      // Update the vehicles array in the form_sell document
      await updateDoc(formSellRef, {
        ...data,
        vehicles: remainingVehicles.map(vehicle => ({
          damage: vehicle.damage || '',
          desiredPrice: vehicle.desiredPrice || '',
          financingBank: vehicle.financingBank || '',
          lien: vehicle.lien || '',
          make: vehicle.make || '',
          miles: vehicle.miles || '',
          model: vehicle.model || '',
          size: vehicle.size || '',
          truckType: vehicle.type || '',
          type: vehicle.type || '',
          vin: vehicle.vin || '',
          year: vehicle.year || ''
        }))
      })
    } catch (error) {
      console.error('Error updating form_sell document:', error)
      showSnackbar('Error updating submission', 'error')
    }
  }

  const addVehiclesToInventory = async (newVehicles: SubmissionVehicle[], kind: 'optic' | 'shield') => {
    try {
      for (const vehicle of newVehicles) {
        const newVehicleInfo = {
          vin: vehicle.vin,
          inventory: kind
        }

        // Update Firestore first
        const formSellRef = doc(db, 'form_sell', submissionId)
        await updateDoc(formSellRef, {
          submittedVehicles: [...submittedVehicles, newVehicleInfo]
        })

        // Then add to inventory
        let newStockNumber: string
        const newVehicle = {
          ...vehicle,
          seller_asking_price: vehicle.desiredPrice,
          who: fleetOwner.name,
          phone_number: fleetOwner.phone,
          email: fleetOwner.email,
          condition: 'Used',
        }
        if (kind === 'optic') {
          const existingStockNumbers = (opticVehicles || [])
            .filter((v: any) => v?.stock_number)
            .map((v: any) => v.stock_number)

          newStockNumber = generateOpticStockNumber(
            existingStockNumbers,
            userInfo?.first_name || '',
            userInfo?.last_name || '',
          )
          newVehicle.stock_number = newStockNumber
          await addFirebaseDocumentToOpticInventory(newVehicle)
        } else {
          const existingStockNumbers = (shieldVehicles || [])
            .filter((v: any) => v?.stock_number)
            .map((v: any) => v.stock_number)

          newStockNumber = generateShieldStockNumber(existingStockNumbers)
          newVehicle.stock_number = newStockNumber
          await addFirebaseDocumentToShieldInventory(newVehicle)
        }
      }

      // Update local state
      setSubmittedVehicles(prev => [
        ...prev,
        ...newVehicles.map(v => ({ vin: v.vin, inventory: kind }))
      ])
      
      setSelectedVehicles([])
      showSnackbar(`${newVehicles.length} vehicle${newVehicles.length > 1 ? 's' : ''} added to ${kind} inventory`, 'success')

    } catch (error) {
      console.error('Error processing vehicles:', error)
      showSnackbar('Error processing vehicles', 'error')
    }
  }

  const handleVehicleSelect = (vehicle: SubmissionVehicle) => {
    const index = selectedVehicles.findIndex(v => v.vin === vehicle.vin)
    if (index > -1) {
      setSelectedVehicles(selectedVehicles.filter(v => v.vin !== vehicle.vin))
    } else {
      setSelectedVehicles([...selectedVehicles, vehicle])
    }
  }

  const handleSubmissionComplete = async () => {
    try {
      await updateSubmissionStatus(submissionId, 'Completed')
      navigate(-1)
    } catch (error) {
      showSnackbar('Failed to update submission status', 'error')
    }
  }

  // Helper function to get vehicle submission status
  const getVehicleSubmissionStatus = (vehicle: SubmissionVehicle) => {
    const submissions = submittedVehicles.filter(v => v.vin === vehicle.vin)
    const isInOptic = submissions.some(v => v.inventory === 'optic')
    const isInShield = submissions.some(v => v.inventory === 'shield')

    if (isInOptic && isInShield) {
      return { label: 'Both', color: 'rgba(76, 175, 80, 0.2)' }
    }
    if (isInShield) {
      return { label: 'Shield', color: 'rgba(244, 67, 54, 0.2)' }
    }
    if (isInOptic) {
      return { label: 'Optic', color: 'rgba(33, 150, 243, 0.2)' }
    }
    return { label: 'Select', color: 'inherit' }
  }

  // Update the isVehicleInInventory and canSubmitToInventory functions
  const isVehicleInInventory = (vehicle: SubmissionVehicle, inventory: 'optic' | 'shield') => {
    return submittedVehicles.some(v => v.vin === vehicle.vin && v.inventory === inventory)
  }

  const canSubmitToInventory = (vehicles: SubmissionVehicle[], inventory: 'optic' | 'shield') => {
    // If any selected vehicle is already in this inventory, disable the button
    return !vehicles.some(vehicle => isVehicleInInventory(vehicle, inventory))
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Button 
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)} 
            sx={{ mr: 2 }}
          >
            Back
          </Button>
          <Typography variant='h4'>
            Submit Vehicles to Inventory
          </Typography>
        </Box>

        {isLoading ? (
          <Box display='flex' justifyContent='center' alignItems='center' minHeight={200}>
            <CircularProgress />
          </Box>
        ) : vehicles1 && vehicles1.length > 0 ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant='determinate' value={(selectedVehicles.length / vehicles1.length) * 100} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant='body2' color='text.secondary'>
                  {`${selectedVehicles.length}/${vehicles1.length}`}
                </Typography>
              </Box>
            </Box>

            <List>
              {vehicles1
                .sort((a: SubmissionVehicle, b: SubmissionVehicle) => parseInt(a.miles) - parseInt(b.miles))
                .map((vehicle: SubmissionVehicle) => (
                  <ListItem
                    key={vehicle.vin}
                    onClick={() => handleVehicleSelect(vehicle)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { filter: 'brightness(0.90)' },
                      padding: 2,
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                      backgroundColor: getVehicleSubmissionStatus(vehicle).color,
                    }}
                  >
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item>
                        <Checkbox 
                          checked={selectedVehicles.some(v => v.vin === vehicle.vin)}
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography variant='h6' gutterBottom>
                          <TruckIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                          {vehicle.year} {vehicle.make} {vehicle.model}
                          {vehicle.type && ` ${vehicle.type}`}
                          {vehicle.size && ` ${vehicle.size}`}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <Typography variant='body2' color='text.secondary'>
                              VIN: {formatField(vehicle.vin)}
                            </Typography>
                          </Grid>
                          
                          <Grid item xs={12} sm={3}>
                            <Typography variant='body2' color='text.secondary'>
                              <SpeedIcon sx={{ verticalAlign: 'middle', marginRight: 1, fontSize: '0.9rem' }} />
                              Mileage: {formatMileage(vehicle.miles)} mi
                            </Typography>
                          </Grid>
                          
                          <Grid item xs={12} sm={3}>
                            <Typography variant='body2' color='text.secondary'>
                              Condition: {formatField(vehicle.user_condition)}
                            </Typography>
                          </Grid>
                          
                          {vehicle.lien && (
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body2' color='text.secondary'>
                                Lien: {formatField(vehicle.lien)}
                              </Typography>
                            </Grid>
                          )}
                          
                          {vehicle.financingBank && (
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body2' color='text.secondary'>
                                Financing Bank: {formatField(vehicle.financingBank)}
                              </Typography>
                            </Grid>
                          )}
                          
                          {vehicle.desiredPrice && (
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body2' color='text.secondary'>
                                Desired Price: ${formatField(vehicle.desiredPrice)}
                              </Typography>
                            </Grid>
                          )}

                          {vehicle.location && (
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body2' color='text.secondary'>
                                Location: {formatField(vehicle.location)}
                              </Typography>
                            </Grid>
                          )}

                          {vehicle.fuel && (
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body2' color='text.secondary'>
                                Fuel: {formatField(vehicle.fuel)}
                              </Typography>
                            </Grid>
                          )}

                          {vehicle.damage && (
                            <Grid item xs={12} sm={6}>
                              <Typography variant='body2' color='text.secondary'>
                                Damage: {formatField(vehicle.damage)}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={
                            selectedVehicles.some(v => v.vin === vehicle.vin)
                              ? 'Selected'
                              : getVehicleSubmissionStatus(vehicle).label
                          }
                          color={
                            selectedVehicles.some(v => v.vin === vehicle.vin)
                              ? 'primary'
                              : getVehicleSubmissionStatus(vehicle).label === 'Both'
                                ? 'success'
                                : getVehicleSubmissionStatus(vehicle).label === 'Shield'
                                  ? 'error'
                                  : getVehicleSubmissionStatus(vehicle).label === 'Optic'
                                    ? 'info'
                                    : 'default'
                          }
                          size='small'
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
            </List>
          </>
        ) : (
          <Box 
            display='flex' 
            flexDirection='column' 
            alignItems='center' 
            justifyContent='center' 
            minHeight={400}
            sx={{ 
              backgroundColor: 'background.paper',
              borderRadius: 1,
              p: 4,
              textAlign: 'center'
            }}
          >
            <InventoryIcon sx={{ fontSize: 100, color: 'text.secondary', mb: 2 }} />
            <Typography variant='h5' color='text.secondary' gutterBottom>
              No Vehicles Available
            </Typography>
            <Typography variant='body1' color='text.secondary' sx={{ maxWidth: 500, mb: 3 }}>
              There are no vehicles to display. This could be because no vehicles were submitted or the submission file was empty.
            </Typography>
            <Button
              variant='contained'
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIcon />}
            >
              Return to Submissions
            </Button>
          </Box>
        )}
      </Box>

      {vehicles1 && vehicles1.length > 0 && (
        <Box sx={{ mt: 'auto', p: 3, display: 'flex', justifyContent: 'flex-end', borderTop: 1, borderColor: 'divider' }}>
          <Box>
            <Button 
              onClick={() => addVehiclesToInventory(selectedVehicles, 'shield')} 
              sx={{ textTransform: 'none', mr: 2 }}
              disabled={selectedVehicles.length === 0 || !canSubmitToInventory(selectedVehicles, 'shield')}
              variant='contained'
              color='error'
            >
              Submit {selectedVehicles.length > 0 ? `(${selectedVehicles.length})` : ''} to Shield Inventory
            </Button>
            <Button 
              onClick={() => addVehiclesToInventory(selectedVehicles, 'optic')} 
              sx={{ 
                textTransform: 'none',
                bgcolor: '#2196f3',
                '&:hover': {
                  bgcolor: '#1976d2'
                }
              }}
              disabled={selectedVehicles.length === 0 || !canSubmitToInventory(selectedVehicles, 'optic')}
              variant='contained'
            >
              Submit {selectedVehicles.length > 0 ? `(${selectedVehicles.length})` : ''} to Optic Inventory
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default FleetListPage
