import { Visibility } from '@mui/icons-material'
import EmailIcon from '@mui/icons-material/Email'
import { Checkbox, IconButton } from '@mui/material'
import { ColDef, EditableCallbackParams, ValueFormatterParams } from 'ag-grid-enterprise'
import { resendVerificationEmail } from 'core/api/users'
import { UserInfoType } from 'core/types/users'
import React from 'react'

type UserColDef = ColDef<User>


export const getUserColumns = async (
  isAdmin: boolean, 
  banks: { [id: string]: string },
  showSnackbar: (message: string, variant: string, duration: string, position: object, persist: boolean, action: string, autoHideDuration: number) => void,
  openNoteDialog: (row: any) => void,
  userInfo: UserInfoType
): Promise<ColDef[]> => {
  const bankOptions: { id: string, name: string }[] = Object.keys(banks).map(id => ({ id, name: banks[id] }))
  const bankIdToNameMap = banks
  const handleOpen = (params: any) => {
    openNoteDialog(params)
  }
  const handleNotifyClick = async (row: { email: string }) => {
    try {
      const sent = await resendVerificationEmail(row.email);
      if (sent) {
        showSnackbar('Verification email sent successfully', 'success', 'medium', { vertical: 'top', horizontal: 'right' }, false, '', 2000);
      } else {
        showSnackbar('Failed to send verification email', 'error', 'medium', { vertical: 'top', horizontal: 'right' }, false, '', 2000);
      }
    } catch (error) {
      showSnackbar((error as Error).message, 'error', 'medium', { vertical: 'top', horizontal: 'right' }, false, '', 2000);
    }
  }

  const userTypeOptions = userInfo?.user_type === 'admin' ? ['admin', 'bank', 'client', 'babyAdmin', 'transporter'] : ['bank', 'client', 'babyAdmin', 'transporter']

  const columns: ColDef[] = [
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 150,
      editable: true,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      width: 150,
      editable: true,
    },
    {
      field: 'user_type',
      headerName: 'User Type',
      width: 100,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: userTypeOptions,
      },
    },
    {
      field: 'bank_id',
      headerName: 'Bank',
      width: 200,
      editable: (params: EditableCallbackParams) => {
        const userType = params.data?.user_type;
        return userType === 'client';
      },
      cellEditor: 'agSelectCellEditor',
      // Sorry, this is a bit of a hack to get the bank id from the bank name TODO: Clean this up
      cellEditorParams: {
        values: bankOptions.map(bank => bank.name),
        keyCreator: (name: string) => {
          const bank = bankOptions.find(bank => bank.name === name);
          return bank ? bank.id : null;
        }
      },
      cellRenderer: (params: EditableCallbackParams) => {
        const userType = params.data?.user_type;
        if (!userType || ['admin', 'babyadmin', 'bank'].includes(userType)) {
          return '';
        }
        const bankName = bankIdToNameMap[params.data?.bank_id] || '';
        return bankName;
      },
      valueFormatter: (params: ValueFormatterParams) => {
        const userType = params.data?.user_type;
        if (!userType || ['admin', 'babyadmin', 'bank'].includes(userType)) {
          return '';
        }
        return bankIdToNameMap[params.value] || 'Unknown Bank';
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      editable: false,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 150,
      editable: true,
    },
    {
      field: 'is_active',
      headerName: 'Active',
      width: 75,
      editable: false,
      cellRenderer: (params: EditableCallbackParams) => (
        <Checkbox 
          checked={Boolean(params.data?.is_active === true)}
          onChange={(event) => {
            const newValue = event.target.checked;
            params.node.setDataValue(params.column.getColId(), newValue);
          }} 
        />
      ),
    },
    {
      field: 'email_verified',
      headerName: 'Email Verified',
      width: 130,
      editable: false,
      cellRenderer: (params: EditableCallbackParams) => (
        <Checkbox
          disabled={Boolean(params.data?.email_verified === true)}
          checked={Boolean(params.data?.email_verified === true)}
          onChange={(event) => {
            const newValue = event.target.checked;
            params.node.setDataValue(params.column.getColId(), newValue);
          }} 
        />
      ),
    },
    {
      field: 'resend_email',
      headerName: 'Send Verification',
      cellRenderer: (params: EditableCallbackParams) => (
        <IconButton 
          disabled={params.data?.email_verified}
          size="small" 
          onClick={() => handleNotifyClick(params.data)}
        >
          <EmailIcon />
        </IconButton>
      ),
      editable: false,      
    },
    {
      field: 'notes',
      headerName: 'Notes',
      cellRenderer: (params: EditableCallbackParams) => {
        return (
          <IconButton size='small' onClick={() => handleOpen(params.data)}>
            <Visibility />
          </IconButton>
        )
      },
      editable: false,
    },
  ]

  return columns
}
