import PersonIcon from '@mui/icons-material/Person'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Box, Button } from '@mui/material'
import { Account, DashboardLayout } from '@toolpad/core'
import Footer from 'components/Footer'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'

export const CoreLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <DashboardLayout
      slots={{
        toolbarAccount: () => AccountViewProfile(),
      }}
      defaultSidebarCollapsed
      slotProps={{
        toolbarAccount: {
          slotProps: {
            signInButton: {
              sx: { display: 'none' }, // Hide the sign-in button
            },
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '70vh',
          width: '100%',
          overflow: 'hidden', // Prevent horizontal scrolling at layout level
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '100%',
            padding: 2,
            boxSizing: 'border-box',
            overflow: 'hidden', // Prevent horizontal dscrolling at content level
          }}
        >
          {children}
        </Box>
        <Footer />
      </Box>
    </DashboardLayout>
  )
}

export default CoreLayout

const AccountViewProfile = () => {
  const { userInfo } = useAuth()
  const navigate = useNavigate()

  return (
    <Account
      slots={{
        menuItems: () => (
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <Button onClick={() => navigate('/profile')} startIcon={<PersonIcon />}>
              View Profile
            </Button>
            {userInfo?.user_type === 'admin' && (
              <Button onClick={() => navigate('/profile#notifications')} startIcon={<NotificationsIcon />}>
                Notifications
              </Button>
            )}
          </Box>
        ),
      }}
    />
  )
}
