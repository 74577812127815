import React from 'react'
import { ColDef, ValueGetterParams, ValueFormatterParams, ICellRendererParams } from 'ag-grid-enterprise'
import { Box, Button, Typography, Switch, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextareaAutosize, Theme } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject'
// import truckIcon from 'assets/Icon-OpticTruckWorks-01.png'
import cutawayIcon from 'assets/cutaway.png'
import stepVanIcon from 'assets/step van.png'
import boxTruckIcon from 'assets/box truck.png'
import cargoVanIcon from 'assets/Cargo Van.png'
import NoPhotographyIcon from '@mui/icons-material/NoPhotography'
import AddLinkDialog from '../Dialogs/AddLinkDialog'
import EditIcon from '@mui/icons-material/Edit'
import VehicleForm from '../Forms/VehicleForm'
import { calculateProfit } from 'core/utils/inventoryUtils'
import { sanitizeNumber } from 'core/utils/inventoryUtils'
import NoteIcon from '@mui/icons-material/Note'
import NotesDialog from '../Dialogs/NotesDialog'

const vehicleStatusOptions = ['Pending Available', 'Available', 'Pending Sale', 'Sold', 'Sold elsewhere']

type VehicleColDef = ColDef<Vehicle>

export const formatCurrency = (value: number | undefined | null): string => {
  if (value === undefined || value === null || isNaN(value)) return ''
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
}

const formatDate = (value: string | Date | undefined | null): string => {
  if (!value) return ''
  try {
    const date = typeof value === 'string' ? new Date(value) : value
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
  } catch (e) {
    return ''
  }
}

export const getInventoryColumns = (isAdmin: boolean, theme: Theme): VehicleColDef[] => {
  const columns: VehicleColDef[] = [
    {
      colId: 'vehicleIcon',
      field: 'vehicleIcon',
      headerName: 'Icon',
      width: 70,
      maxWidth: 70,
      minWidth: 70,
      editable: false,
      sortable: false,
      filter: false,
      suppressHeaderMenuButton: true,
      suppressSizeToFit: true,
      enableRowGroup: false,
      enablePivot: false,
      lockPosition: true,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px',
        overflow: 'hidden'
      },
      cellRenderer: (params: ICellRendererParams) => {
        const getDefaultIcon = (type: string | undefined) => {
          if (!type) return stepVanIcon // Return default icon if type is undefined
          
          const lowerType = type.toLowerCase()
          switch (lowerType) {
            case 'cutaway':
              return cutawayIcon
            case 'step van':
              return stepVanIcon
            case 'box truck':
              return boxTruckIcon
            case 'cargo van':
              return cargoVanIcon
            default:
              return stepVanIcon
          }
        }
        
        // Add null check for params.data
        if (!params.data) {
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
                p: 0.25,
                overflow: 'hidden',
                borderRadius: 1
              }}
            >
              <Box
                component='img'
                src={stepVanIcon}
                alt='Default Truck'
                sx={{
                  width: '48px',
                  height: '48px',
                  objectFit: 'contain',
                  borderRadius: 1,
                  cursor: 'pointer',
                  display: 'block',
                  p: 0.25,
                  mixBlendMode: 'multiply',
                }}
              />
            </Box>
          )
        }

        const icon = getDefaultIcon(params.data?.type)
        const [formOpen, setFormOpen] = React.useState(false)

        // Get the latest data whenever the form opens
        const currentRowData = React.useMemo(() => {
          if (!formOpen) return null
          return params.api.getRowNode(params.data.id)?.data || params.data
        }, [formOpen, params.data, params.api])

        const handleSubmit = async (data: { [key: string]: string | number | boolean }) => {
          const sanitizedData = { ...data }
          sanitizedData.miles = sanitizeNumber(sanitizedData.miles)
          sanitizedData.optic_list_price = sanitizeNumber(sanitizedData.optic_list_price)
          sanitizedData.seller_asking_price = sanitizeNumber(sanitizedData.seller_asking_price)

          const newValues = Object.fromEntries(Object.entries(sanitizedData).filter(([, value]) => value !== undefined))
          
          if (newValues.optic_list_price && newValues.seller_asking_price) {
            newValues.profit = calculateProfit(
              String(newValues.seller_asking_price), 
              String(newValues.optic_list_price)
            )
          }

          // Update the grid row data
          const node = params.node
          if (node) {
            node.setData({ ...params.data, ...newValues })
          }
          setFormOpen(false)
        }
        

        return (
          <>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
                p: 0.25,
                overflow: 'hidden',
                borderRadius: 1
              }}
            >
              <Box
                component='img'
                src={icon}
                alt='Default Truck'
                onDoubleClick={() => setFormOpen(true)}
                sx={{
                  width: '48px',
                  height: '48px',
                  objectFit: 'contain',
                  borderRadius: 1,
                  cursor: 'pointer',
                  display: 'block',
                  p: 0.25,
                  mixBlendMode: 'multiply',
                }}
              />
            </Box>

            {formOpen && currentRowData && (
              <VehicleForm
                open={formOpen}
                vehicle={currentRowData}
                onClose={() => setFormOpen(false)}
                onSubmit={handleSubmit}
                generateStockNumber={() => currentRowData.stock_number}
                isEdit={true}
              />
            )}
          </>
        )
      },
    } as VehicleColDef,
    {
      field: 'type',
      headerName: 'Type',
      filter: 'agMultiColumnFilter',
      cellDataType: false,
      width: 100,
    } as VehicleColDef,
    {
      field: 'stock_number',
      headerName: 'Stock Number',
      filter: 'agTextColumnFilter',
      width: 140,
      editable: false,
      suppressSizeToFit: true,
      cellRenderer: 'agGroupCellRenderer',
      // cellRendererParams: {
      //   innerRenderer: (params: ICellRendererParams) => <Typography>{params.value}</Typography>,
      // },
    } as VehicleColDef,
    {
      field: 'who',
      headerName: 'Who',
      filter: 'agMultiColumnFilter',
      width: 120,
    } as VehicleColDef,
    {
      field: 'condition',
      headerName: 'Cond.',
      filter: 'agMultiColumnFilter',
      width: 120,
      minWidth: 70,
    } as VehicleColDef,
    {
      field: 'year',
      headerName: 'Year',
      filter: 'agMultiColumnFilter',
      minWidth: 70,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined) return ''
        return `${params.value}`
      },
      // cellClass: 'ag-right-aligned-cell',
    } as VehicleColDef,
    {
      field: 'make',
      headerName: 'Make',
      filter: 'agMultiColumnFilter',
      width: 120,
    } as VehicleColDef,
    {
      field: 'model',
      headerName: 'Model',
      filter: 'agMultiColumnFilter',
      width: 120,
      minWidth: 90,
    } as VehicleColDef,
    {
      field: 'size',
      headerName: 'Size',
      filter: 'agMultiColumnFilter',
      width: 100,
    } as VehicleColDef,
    {
      field: 'miles',
      headerName: 'Miles',
      filter: 'agMultiColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value === undefined || params.value === null || isNaN(params.value)) return ''
        return `${params.value.toLocaleString()} mi`
      },
      valueGetter: (params: ValueGetterParams) => {
        const value = params.data?.miles
        return value === undefined || value === null || isNaN(value) ? null : Number(value)
      },
      cellClass: 'ag-left-aligned-cell',
      aggFunc: 'avg',
    } as VehicleColDef,
    {
      field: 'seller_asking_price',
      headerName: 'Seller Asking Price',
      filter: 'agMultiColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      // cellClass: 'ag-left-aligned-cell',
      aggFunc: 'avg',
    } as VehicleColDef,
    {
      field: 'optic_list_price',
      headerName: 'List Price',
      filter: 'agMultiColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      valueGetter: (params: ValueGetterParams) => {
        const value = parseFloat(params.data?.optic_list_price || '0')
        return isNaN(value) ? null : value
      },
      cellClass: 'ag-left-aligned-cell',
      aggFunc: 'avg',
    } as VehicleColDef,
    {
      field: 'profit',
      headerName: 'Profit',
      filter: 'agMultiColumnFilter',
      valueGetter: (params: ValueGetterParams<Vehicle>) => {
        if (!params.data) return null
        
        const opticPrice = parseFloat(params.data.optic_list_price || '0')
        const sellerPrice = parseFloat(params.data.seller_asking_price || '0')
        const repairCost = parseFloat(params.data.repair_cost || '0')
        const shippingCost = parseFloat(params.data.shipping_cost || '0')

        // Return null if optic price is 0 or if any required values are NaN
        if (opticPrice === 0 || isNaN(opticPrice) || isNaN(sellerPrice) || 
            isNaN(repairCost) || isNaN(shippingCost)) {
          return null
        }

        return opticPrice - sellerPrice - repairCost - shippingCost
      },
      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      cellStyle: (params: ValueFormatterParams) => {
        const opticPrice = parseFloat(params.data?.optic_list_price || '0')
        const sellerPrice = parseFloat(params.data?.seller_asking_price || '0')
        
        if (params.value < 0 && opticPrice !== 0) return { color: 'red' }
        if (opticPrice === 0 && sellerPrice > 0) return { color: 'blue' }
        return null
      },
      cellClass: (params: ValueFormatterParams) => {
        const opticPrice = parseFloat(params.data?.optic_list_price || '0')
        if (opticPrice === 0) return 'profit-unknown'
        if (params.value > 0) return 'profit-positive'
        if (params.value < 0) return 'profit-negative'
        return ''
      },
      aggFunc: 'avg',
    } as VehicleColDef,
    {
      field: 'vin',
      headerName: 'VIN',
      filter: 'agMultiColumnFilter',
      flex: 1,
      minWidth: 200,
      editable: true,
    } as VehicleColDef,
    {
      field: 'link',
      headerName: 'Link',
      filter: 'agMultiColumnFilter',
      width: 120,
      cellRenderer: (params: ICellRendererParams) => {
        const [dialogOpen, setDialogOpen] = React.useState(false)

        const handleAddLink = (newLink: string) => {
          const node = params.node
          if (node) {
            node.setDataValue('link', newLink)
          }
        }

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 0.5,
              width: '100%',
              height: '100%',
              padding: '2px',
            }}
          >
            <Button
              variant='text'
              size='small'
              sx={{
                minWidth: '40px',
                padding: '2px 6px',
                fontSize: '0.75rem',
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.light',
                  opacity: 0.9,
                },
              }}
              onClick={() => {
                if (params.value) {
                  window.open(params.value, '_blank')
                } else {
                  setDialogOpen(true)
                }
              }}
            >
              {params.value ? 'View' : 'Add'}
            </Button>

            {params.value && (
              <IconButton
                size='small'
                onClick={() => setDialogOpen(true)}
                sx={{
                  padding: 0,
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                    opacity: 0.9,
                  },
                }}
              >
                <EditIcon sx={{ fontSize: 14 }} />
              </IconButton>
            )}

            <AddLinkDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              onSubmit={handleAddLink}
              currentLink={params.value}
            />
          </Box>
        )
      },
      editable: false,
    } as VehicleColDef,
    {
      field: 'photos',
      headerName: 'Photo',
      width: 50,
      maxWidth: 80,
      editable: false,
      sortable: false,
      filter: false,
      suppressHeaderMenuButton: true,
      suppressSizeToFit: true,
      cellRenderer: (params: ICellRendererParams) => {
        // Add null check for params.data
        if (!params.data) {
          return (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tooltip title='No data'>
                <NoPhotographyIcon
                  sx={{
                    width: 20,
                    height: 20,
                    color: 'text.disabled',
                  }}
                />
              </Tooltip>
            </Box>
          )
        }

        const { data: photos } = usePhotosForInventoryObject('master_inventory', params.data.id,
          {
            onlyMainPhoto: true
          }
        )

        return !photos || photos.length === 0 ? (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Tooltip title='Photos needed!'>
              <NoPhotographyIcon
                sx={{
                  width: 20,
                  height: 20,
                  color: 'primary.main',
                }}
              />
            </Tooltip>
          </Box>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              component='img'
              src={photos.find(p => p.position === 'main')?.url || photos[0]?.url}
              alt='Vehicle'
              sx={{
                width: 40,
                height: 40,
                objectFit: 'contain',
                borderRadius: 2,
              }}
            />
          </div>
        )
      },
    } as VehicleColDef,
    {
      field: 'id',
      headerName: 'ID',
      filter: 'agMultiColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
        suppressAndOrCondition: true,
      },
      flex: 1,
      minWidth: 100,
      hide: true,
      editable: false,
    } as VehicleColDef,
    {
      field: 'date_added',
      headerName: 'Date Added',
      filter: 'agDateColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatDate(params.value),
      sortable: true,
      sort: 'desc',
      editable: false,
    } as VehicleColDef,
    {
      field: 'fuel',
      headerName: 'Fuel',
      hide: true,
      minWidth: 90,
      filter: 'agMultiColumnFilter',
    } as VehicleColDef,
    {
      field: 'location',
      filter: 'agMultiColumnFilter',

      headerName: 'Loc.',
      width: 150,
      minWidth: 70,
    } as VehicleColDef,
    {
      field: 'cameras',
      headerName: 'Cameras',
      width: 100,
      hide: true,
    } as VehicleColDef,
    {
      field: 'sold_date',
      headerName: 'Sold Date',
      filter: 'agDateColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => formatDate(params.value),
    } as VehicleColDef,
    {
      field: 'truck_notes',
      headerName: 'Truck Notes',
      width: 100,
      editable: false,
      suppressKeyboardEvent: () => true,
      cellRenderer: (params: ICellRendererParams) => {
        const [dialogOpen, setDialogOpen] = React.useState(false)

        const handleSave = (newNotes: string) => {
          const node = params.node
          if (node) {
            node.setDataValue('truck_notes', newNotes)
          }
        }

        const handleClick = (event: React.MouseEvent) => {
          event.stopPropagation()
          event.preventDefault()
          setDialogOpen(true)
        }

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            <Tooltip title={params.value ? 'Edit notes' : 'Add notes'}>
              <IconButton
                size="small"
                onClick={handleClick}
                sx={{ 
                  color: params.value ? 'primary.main' : 'text.secondary',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <NoteIcon />
              </IconButton>
            </Tooltip>

            <NotesDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              onSave={handleSave}
              initialValue={params.value || ''}
              title="Truck Notes"
            />
          </Box>
        )
      },
      tooltipValueGetter: (params) => params.value || 'No notes',
    } as VehicleColDef,
    {
      field: 'phone_number',
      headerName: 'Phone Number',
      filter: 'agMultiColumnFilter',

      width: 150,
    } as VehicleColDef,
    {
      field: 'email',
      headerName: 'Email',
      filter: 'agMultiColumnFilter',
      width: 200,
    } as VehicleColDef,
    {
      field: 'follow_up_notes',
      headerName: 'Follow Up Notes',
      filter: 'agMultiColumnFilter',

      tooltipField: 'follow_up_notes',
      hide: true,
    } as VehicleColDef,
    {
      field: 'entered_by',
      headerName: 'Entered By',
      filter: 'agMultiColumnFilter',
      width: 150,
    } as VehicleColDef,
    {
      field: 'payoff_amount',
      headerName: 'Payoff Amount',
      filter: 'agMultiColumnFilter',

      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      cellClass: 'ag-left-aligned-cell',
    } as VehicleColDef,
    {
      field: 'repair_cost',
      headerName: 'Repair Cost',
      filter: 'agMultiColumnFilter',

      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      cellClass: 'ag-left-aligned-cell',
    } as VehicleColDef,
    {
      field: 'shipping_cost',
      headerName: 'Shipping Cost',
      filter: 'agMultiColumnFilter',

      valueFormatter: (params: ValueFormatterParams) => formatCurrency(params.value),
      cellClass: 'ag-left-aligned-cell',
    } as VehicleColDef,

    {
      field: 'show_on_website',
      headerName: 'On Website',
      filter: 'agMultiColumnFilter',
      width: 120,
      minWidth: 120,
      suppressSizeToFit: true,
      editable: false,
      cellRenderer: (params: ICellRendererParams) => (
        <div
          style={{ 
            width: '100%', 
            height: '100%', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            padding: '0 8px'
          }}
        >
          <Switch
            checked={params.value}
            onChange={() => {
              const node = params.node
              if (node) {
                node.setDataValue('show_on_website', !params.value)
              }
            }}
            color='primary'
            size="small"
          />
        </div>
      ),
      cellClass: (params: any) => (params.value ? 'show-on-website' : 'hide-on-website'),
    } as VehicleColDef,
    {
      field: 'status',
      headerName: 'Status',
      filter: 'agMultiColumnFilter',

      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: vehicleStatusOptions,
      },
      cellClass: (params: any) => {
        if (params.value) {
          return `status-${params.value.toLowerCase().replace(' ', '-')}`
        }
        return 'status-unknown'
      },
      // filter: 'agSetColumnFilter',
      // filterParams: {
      //   values: vehicleStatusOptions,
      //   defaultToNothingSelected: false,
      //   excludeValues: ['Sold', 'Sold elsewhere']
      // },
      // defaultFilter: {
      //   type: 'notIn',
      //   values: ['Sold', 'Sold elsewhere']
      // }
    } as VehicleColDef,
  ]

  // Filter columns based on admin status if needed
  if (!isAdmin) {
    return columns.filter(
      col =>
        !['who', 'vin', 'seller_asking_price', 'profit', 'phone_number', 'show_on_website'].includes(
          col.field as string,
        ),
    )
  }

  return columns
}