import { db } from 'core/config/firebase'
import { doc, getDoc } from 'firebase/firestore'
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteStorageFile } from 'core/api/firebaseStorage/deleteStorageFile'
import { uploadFileToInventory } from 'core/api/inventory/addFileToInventory'
import { updatePhotoPosition } from 'core/api/inventory/updatePhotoPosition'

const storage = getStorage()

const getAdditionalPhotosForInventoryObject = async (
  collectionName: string,
  inventoryObjectId: string,
  definedInventoryPhotos: VehiclePhoto[],
  subcollection?: { name: string; parentDocId: string }
): Promise<VehiclePhoto[]> => {
  const storagePath = subcollection 
    ? `${collectionName}/${subcollection.parentDocId}/${subcollection.name}/${inventoryObjectId}`
    : `${collectionName}/${inventoryObjectId}`
  const storageRef = ref(storage, storagePath)

  const photos: VehiclePhoto[] = []

  const storageObjects = await listAll(storageRef)

  for (const obj of storageObjects.items) {
    const url = await getDownloadURL(obj)

    if (definedInventoryPhotos.some(photo => photo.url === url)) {
      continue
    } else {
      photos.push({
        id: inventoryObjectId,
        name: obj.name,
        fullPath: obj.fullPath,
        url,
      })
    }
  }
  return photos
}

const fetchPhotosForInventoryObject = async (
  collectionName: string,
  inventoryObjectId: string,
  subcollection?: { name: string; parentDocId: string },
  onlyMainPhoto?: boolean
): Promise<VehiclePhoto[]> => {
  const docRef = subcollection
    ? doc(db, collectionName, subcollection.parentDocId, subcollection.name, inventoryObjectId)
    : doc(db, collectionName, inventoryObjectId)
  const docSnap = await getDoc(docRef)
  const definedPhotos: VehiclePhoto[] = []

  if (!docSnap.exists()) {
    console.error('No such document!')
    return []
  }

  const data = docSnap.data()

  if (!data) {
    console.error('No data found for document!')
    return []
  }

  const errorPhotos: unknown[] = []

  if (data.photos) {
    const photos: Record<string, string> = data.photos
    for (const [key, value] of Object.entries(photos)) {
      try {
        const storagePath = subcollection
          ? `${collectionName}/${subcollection.parentDocId}/${subcollection.name}/${inventoryObjectId}/${value}`
          : `${collectionName}/${inventoryObjectId}/${value}`
        const storageRef = ref(storage, storagePath)

        if (!storageRef) {
          continue
        }

        const url = await getDownloadURL(storageRef)

        definedPhotos.push({
          id: inventoryObjectId,
          name: value,
          url: url,
          position: key,
          fullPath: '',
        })
      } catch (error) {
        console.error('Error getting download URL for photo', value, error)
        errorPhotos.push(value)
      }
    }
  }
  if (!onlyMainPhoto) {
    const additionalPhotos = await getAdditionalPhotosForInventoryObject(
      collectionName,
    inventoryObjectId,
    definedPhotos,
      subcollection
    )

    return [...definedPhotos, ...additionalPhotos]
  }

  return definedPhotos
}

export const usePhotosForInventoryObject = (
  collectionName: string, 
  inventoryObjectId: string,
  options?: { enabled?: boolean, onlyMainPhoto?: boolean  },
  subcollection?: { name: string; parentDocId: string }
) => {
  const queryClient = useQueryClient()

  const queryKey = subcollection
    ? ['photos', collectionName, subcollection.parentDocId, subcollection.name, inventoryObjectId, options?.onlyMainPhoto]
    : ['photos', collectionName, inventoryObjectId, options?.onlyMainPhoto]

  const query = useQuery({
    queryKey,
    queryFn: () => fetchPhotosForInventoryObject(collectionName, inventoryObjectId, subcollection, options?.onlyMainPhoto),
    enabled: options?.enabled !== false && !!collectionName && !!inventoryObjectId,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  })

  const deletePhotosMutation = useMutation({
    mutationFn: async (photosToDelete: VehiclePhoto[]) => {
      for (const photo of photosToDelete) {
        await deleteStorageFile(photo.url)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
    }
  })

  const uploadPhotosMutation = useMutation({
    mutationFn: async (photosToUpload: { file: File; previewBlobUrl: string }[]) => {
      return Promise.all(
        photosToUpload.map(({ file }) => 
          uploadFileToInventory(
            subcollection ? `${collectionName}/${subcollection.parentDocId}/${subcollection.name}` : collectionName,
            inventoryObjectId,
            file,
            true
          )
        ),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
    }
  })

  const updatePositionMutation = useMutation({
    mutationFn: async (params: { name: string; position: PhotoPosition }) => {
      await updatePhotoPosition(
        subcollection ? `${collectionName}/${subcollection.parentDocId}/${subcollection.name}` : collectionName,
        inventoryObjectId,
        params.name,
        params.position
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
    }
  })

  return {
    ...query,
    deletePhotos: deletePhotosMutation.mutate,
    uploadPhotos: uploadPhotosMutation.mutate,
    updatePosition: updatePositionMutation.mutate,
    isDeleting: deletePhotosMutation.isPending,
    isUploading: uploadPhotosMutation.isPending,
    isUpdating: updatePositionMutation.isPending,
  }
}
