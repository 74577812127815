import { useCallback, useState, useMemo, useEffect } from 'react'
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  DocumentData,
  DocumentReference,
  orderBy,
  query,
  onSnapshot,
  getDoc,
  where,
  setDoc,
} from 'firebase/firestore'
import { db } from 'core/config/firebase'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'contexts/snackBarContext'
import { vehicleConverter } from 'converters/vehicleConverter'
import { useAuth } from 'contexts/AuthContext'
import isEqual from 'lodash/isEqual'

interface UseInventoryOptions {
  onSuccess?: () => void
  onError?: (error: Error) => void
  dontShowUpdates?: boolean
}

interface UpdateDocumentParams {
  id: string
  data: Partial<DocumentData>
}

// Add this interface for filter state
export interface VehicleFilters {
  vin: string
  make: string
  model: string
  stockNumber: string
  type: string
  size: string
  fuel: string
  miles: [number, number]
  listPrice: [number, number]
  askingPrice: [number, number]
  potentialProfit: [number, number]
  location: string
  contact: string
  addedDate: Date | null
  isAvailable: boolean
}

export const initialFilters: VehicleFilters = {
  vin: '',
  make: '',
  model: '',
  stockNumber: '',
  type: '',
  size: '',
  fuel: '',
  miles: [0, 500000],
  listPrice: [0, 100000],
  askingPrice: [0, 100000],
  potentialProfit: [0, 50000],
  location: '',
  contact: '',
  addedDate: null,
  isAvailable: false,
}

interface HistoryEntry {
  id: string
  documentId: string
  timestamp: any
  alphas?: Record<string, any>
  deltas?: Record<string, any>
  fullData?: Record<string, any>
  modifiedBy: string
  collection: string
  type: 'update' | 'create' | 'delete'
  identifier: string
  docId: string
}

export const useInventory = (
  inventoryCollection: string,
  subcollection?: {
    name: string
    parentDocId: string
  },
  options?: UseInventoryOptions,
) => {
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { userInfo } = useAuth()

  // Create collection ref based on whether subcollection is specified
  const collectionRef = subcollection
    ? collection(db, inventoryCollection, subcollection.parentDocId, subcollection.name)
    : collection(db, inventoryCollection)

  const queryKey = subcollection
    ? [inventoryCollection, subcollection.parentDocId, subcollection.name]
    : [inventoryCollection]

  const {
    data: vehicles,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const collectionWithConverter = collectionRef.withConverter(vehicleConverter)
      const snapshot = await getDocs(collectionWithConverter)
      const docs = snapshot.docs.map(doc => ({
        // @ts-expect-error - overwrite incase an id field is accidently written
        id: doc.id,
        ...doc.data(),
      }))

      return docs.sort((a, b) => {
        if (!a.date_added && !b.date_added) return 0
        if (!a.date_added) return 1
        if (!b.date_added) return -1
        return b.date_added.localeCompare(a.date_added)
      })
    },
    staleTime: 1000 * 60 * 5,
  })

  const refetchVehicles = useCallback(async () => {
    await refetch()
  }, [refetch, showSnackbar])

  const addDocument = useMutation({
    mutationFn: async (data: DocumentData) => {
      const docRef = await addDoc(collectionRef, { ...data, entered_by: userInfo?.email })
      return docRef.id;
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Document added successfully', 'success')
      }
      queryClient.invalidateQueries({ queryKey })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Error adding document', 'error')
      }
      console.error('Error adding document:', error)
      options?.onError?.(error)
    },
  })

  const updateDocument = useMutation({
    mutationFn: async ({ id, data, isRevert = false }: UpdateDocumentParams & { isRevert?: boolean }) => {
      const docRef = doc(collectionRef, id) as DocumentReference

      if (isRevert) {
        await updateDoc(docRef, {
          ...data,
          modified_by: userInfo?.email,
        })
        return { id, data }
      }

      const docSnap = await getDoc(docRef)
      const currentData = docSnap.data()

      const actualChanges = Object.entries(data).reduce(
        (acc, [key, value]) => {
          if (!isEqual(currentData?.[key], value)) {
            acc[key] = value
          }
          return acc
        },
        {} as Record<string, any>,
      )

      if (Object.keys(actualChanges).length > 0) {
        await updateDoc(docRef, {
          ...actualChanges,
          modified_by: userInfo?.email,
        })
      }

      return { id, data: actualChanges }
    },
    onMutate: async ({ id, data }) => {
      await queryClient.cancelQueries({ queryKey })
      const previousInventory = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (old: Vehicle[] | undefined) => {
        if (!old) return old
        return old.map((item: Vehicle) => (item.id === id ? { ...item, ...data } : item))
      })

      return { previousInventory }
    },
    onError: (err, variables, context) => {
      if (context?.previousInventory) {
        queryClient.setQueryData(queryKey, context.previousInventory)
      }
      if (!options?.dontShowUpdates) {
        showSnackbar('Error updating document', 'error')
      }
      console.error('Error updating document:', err)
      options?.onError?.(err)
    },
    onSuccess: result => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Changes saved successfully', 'success')
      }
      options?.onSuccess?.()
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey })
    },
  })

  const deleteDocument = useMutation({
    mutationFn: async (id: string) => {
      const docRef = doc(collectionRef, id)
      await deleteDoc(docRef)
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Document deleted successfully', 'success')
      }
      queryClient.invalidateQueries({ queryKey })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Error deleting document', 'error')
      }
      console.error('Error deleting document:', error)
      options?.onError?.(error)
    },
  })

  const bulkDeleteDocuments = useMutation({
    mutationFn: async (ids: string[]) => {
      await Promise.all(ids.map(id => deleteDoc(doc(collectionRef, id))))
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Documents deleted successfully', 'success')
      }
      queryClient.invalidateQueries({ queryKey })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Error deleting documents', 'error')
      }
      console.error('Error deleting documents:', error)
      options?.onError?.(error)
    },
  })

  const handleCellValueChange = useCallback(
    (id: string, field: string, value: any) => {
      console.log(id, field, value, 'id, field, value')
      updateDocument.mutate(
        {
          id,
          data: { [field]: value },
        },
        {
          onSuccess: () => {
            // Invalidate the lastUserAction query for this user
            queryClient.invalidateQueries({
              queryKey: ['lastUserAction'], // This will match any lastUserAction query
            })
          },
        },
      )
    },
    [updateDocument, queryClient],
  )

  const restoreDocumentMutation = useMutation({
    mutationFn: async (data: { id: string; fullData: Record<string, any> }) => {
      const { id, fullData } = data
      await setDoc(doc(collectionRef, id), fullData)
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Document restored successfully', 'success')
      }
      queryClient.invalidateQueries({ queryKey })
      options?.onSuccess?.()
    },
    onError: (error: Error) => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Error restoring document', 'error')
      }
      console.error('Error restoring document:', error)
      options?.onError?.(error)
    },
  })

  return {
    vehicles,
    isLoading,
    isError,
    error,
    addDocument: addDocument.mutate,
    updateDocument: updateDocument.mutate,
    deleteDocument: deleteDocument.mutate,
    bulkDeleteDocuments: bulkDeleteDocuments.mutate,
    handleCellValueChange,
    queryClient,
    refetchVehicles,
    restoreDocument: restoreDocumentMutation.mutate,
  }
}
