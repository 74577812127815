import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  Stack,
  Typography,
  Chip,
} from '@mui/material'
import { UserInfoType } from 'core/types/users'

interface ShareDialogProps {
  open: boolean
  onClose: () => void
  list: InventoryList | null
  users: UserInfoType[]
  onShare: (userIds: string[]) => Promise<void>
}

export const ShareDialog: React.FC<ShareDialogProps> = ({
  open,
  onClose,
  list,
  users,
  onShare,
}) => {
  const [selectedUsers, setSelectedUsers] = useState<UserInfoType[]>([])
  const [loading, setLoading] = useState(false)

  // Initialize selected users when dialog opens
  useEffect(() => {
    if (open && list?.associatedUserIds) {
      const currentUsers = users.filter(user => 
        list.associatedUserIds?.includes(user.id)
      )
      setSelectedUsers(currentUsers)
    }
  }, [open, list, users])

  const handleShare = async () => {
    setLoading(true)
    try {
      await onShare(selectedUsers.map(user => user.id))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Share List: {list?.name}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2" color="text.secondary">
            Select users to share this list with. Users will be able to view and download the list.
          </Typography>
          <Autocomplete
            multiple
            options={users}
            value={selectedUsers}
            onChange={(_, newValue) => setSelectedUsers(newValue)}
            getOptionLabel={(option) => 
              `${option.email || ''}${option.company_name ? ` (${option.company_name})` : ''}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Share with Users"
                placeholder="Search users..."
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.email}
                  {...getTagProps({ index })}
                  key={option.id}
                />
              ))
            }
            renderOption={(props, option) => (
              <li {...props}>
                <Stack>
                  <Typography>{option.email || 'No email'}</Typography>
                  {option.company_name && (
                    <Typography variant="caption" color="text.secondary">
                      {option.company_name}
                    </Typography>
                  )}
                </Stack>
              </li>
            )}
            filterOptions={(options, { inputValue }) => {
              const searchTerms = inputValue.toLowerCase().split(' ')
              return options.filter(option => 
                searchTerms.every(term =>
                  (option.email?.toLowerCase() || '').includes(term) ||
                  (option.company_name?.toLowerCase() || '').includes(term) ||
                  (option.first_name?.toLowerCase() || '').includes(term) ||
                  (option.last_name?.toLowerCase() || '').includes(term)
                )
              )
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleShare}
          variant="contained"
          disabled={loading}
        >
          {loading ? 'Sharing...' : 'Share'}
        </Button>
      </DialogActions>
    </Dialog>
  )
} 