export function generateOpticStockNumber(existingStockNumbers: string[], firstName: string, lastName: string) {
  const prefix = firstName.charAt(0).toUpperCase().concat(lastName.charAt(0).toUpperCase());
  return generateNextStockNumber(prefix, existingStockNumbers);
}

export function generateShieldStockNumber(existingStockNumbers: string[]) {
  return generateNextStockNumber('SH', existingStockNumbers)
}

export function generateTransferedOpticStockNumber(existingStockNumbers: string[], firstName: string, lastName: string) {
  const prefix = firstName.charAt(0).toUpperCase().concat(lastName.charAt(0).toUpperCase());
  return generateNextStockNumber(`${prefix}V`, existingStockNumbers) ;
}

export function generateTransferedShieldStockNumber(existingStockNumbers: string[]) {
  return generateNextStockNumber('SHV', existingStockNumbers)
}


function generateNextStockNumber(prefix: string, existingStockNumbers: string[]) {
  const relevantCodes = existingStockNumbers
    .filter(code => code.startsWith(prefix.slice(0, 2)))
    .map(code => parseInt(code.substring(2, 10)))
    .filter(code => !isNaN(code))
  
  const nextNumber = relevantCodes.length > 0 ? Math.max(...relevantCodes) + 1 : 1
  const nextCode = `${prefix}${nextNumber.toString().padStart(5, '0')}`
  
  return nextCode;
}
