import React, { useState } from 'react'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Avatar, Box, Container, Typography } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import { useAuth } from 'contexts/AuthContext'
import dayjs from 'dayjs'
import { useManageUsers } from 'hooks/useManageUsers'
import NotificationSection from './components/NotificationSection'
import { NotificationPreferences, NotificationType } from 'core/types/users'

type ExpandedSection = '' | 'account' | 'notifications'

const Profile = (): JSX.Element => {
  const { userInfo } = useAuth()
  const { updateUser } = useManageUsers()
  const userData = {
    name: userInfo?.first_name + ' ' + userInfo?.last_name,
    first_name: userInfo?.first_name,
    last_name: userInfo?.last_name,
    email: userInfo?.email,
    company: userInfo?.company_name,
  }

  const notifactionTypes: NotificationType[] = [
    'inventory_sold_event',
    'inventory_published_on_site_event',
    'user_created_event',
  ]
  const [notificationPreferences, setNotificationPreferences] = useState<NotificationPreferences>(
    userInfo?.notifications ||
      (Object.fromEntries(
        notifactionTypes.map(type => [type, { email: false, sms: false }]),
      ) as NotificationPreferences),
  )

  // Initialize expanded section based on URL hash
  const [expandedSection, setExpandedSection] = useState<ExpandedSection>(() => {
    const hash = window.location.hash.slice(1)
    return (hash as ExpandedSection) || ''
  })
  const [expandedSubsections, setExpandedSubsections] = useState<Set<string>>(new Set())

  // Listen for hash changes
  React.useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1)
      setExpandedSection(hash as ExpandedSection)
    }

    window.addEventListener('hashchange', handleHashChange)
    return () => window.removeEventListener('hashchange', handleHashChange)
  }, [])

  const notificationSections = [
    {
      title: 'Inventory Notifications',
      types: ['inventory_sold_event', 'inventory_published_on_site_event'],
    },
    {
      title: 'User Notifications',
      types: ['user_created_event'],
    },
  ]

  const handleMainSectionExpand = (section: ExpandedSection) => {
    setExpandedSection(expandedSection === section ? '' : section)
    // Update URL hash when section changes
    if (expandedSection === section) {
      window.location.hash = ''
    } else {
      window.location.hash = section
    }
  }

  const handleSubsectionExpand = (subsection: string) => {
    setExpandedSubsections(prev => {
      const newSet = new Set(prev)
      if (newSet.has(subsection)) {
        newSet.delete(subsection)
      } else {
        newSet.add(subsection)
      }
      return newSet
    })
  }

  const updateNotificationPreference = (updatedPreferences: NotificationPreferences) => {
    setNotificationPreferences(updatedPreferences)
    if (!userInfo?.firestoreDocId) return

    updateUser({
      id: userInfo.firestoreDocId,
      notifications: updatedPreferences,
      timestamp: dayjs().toDate(),
    })
  }

  return (
    <Container component='main' maxWidth='md'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        {/* Profile Header */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: deepPurple[500], width: 56, height: 56 }}>
            {userData.first_name?.charAt(0)}
            {userData.last_name?.charAt(0)}
          </Avatar>
          <Typography component='h1' variant='h5'>
            {userData.name}
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            {userData.email}
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            {userData.company}
          </Typography>
        </Box>

        {/* Account Settings */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 1,
            p: 4,
          }}
        >
          <Box
            onClick={() => handleMainSectionExpand('account')}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pb: 2,
            }}
          >
            <Typography variant='h5' color='primary'>
              Profile Information
            </Typography>
            {expandedSection === 'account' ? <ExpandLess /> : <ExpandMore />}
          </Box>
          {expandedSection === 'account' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3, mx: 2 }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 2fr',
                  alignItems: 'center',
                  p: 1.5,
                  '&:hover': {
                    bgcolor: 'action.hover',
                    borderRadius: 1,
                  },
                }}
              >
                <Typography variant='subtitle1' color='text.secondary'>
                  Name
                </Typography>
                <Typography>{userData.name}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 2fr',
                  alignItems: 'center',
                  p: 1.5,
                  '&:hover': {
                    bgcolor: 'action.hover',
                    borderRadius: 1,
                  },
                }}
              >
                <Typography variant='subtitle1' color='text.secondary'>
                  Email
                </Typography>
                <Typography>{userData.email}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 2fr',
                  alignItems: 'center',
                  p: 1.5,
                  '&:hover': {
                    bgcolor: 'action.hover',
                    borderRadius: 1,
                  },
                }}
              >
                <Typography variant='subtitle1' color='text.secondary'>
                  Company
                </Typography>
                <Typography>{userData.company}</Typography>
              </Box>
            </Box>
          )}
        </Box>

        {/* Notifications Settings */}
        {userInfo?.user_type === 'admin' && (
          <Box sx={{ bgcolor: 'background.paper', borderRadius: 2, boxShadow: 1, p: 3 }}>
            <Box
              onClick={() => handleMainSectionExpand('notifications')}
              sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant='h4' sx={{ mb: 3 }}>
                Notifications
              </Typography>
              {expandedSection === 'notifications' ? <ExpandLess /> : <ExpandMore />}
            </Box>
            {/* Individual notification settings */}
            {expandedSection === 'notifications' &&
              notificationSections.map(section => (
                <NotificationSection
                  key={section.title}
                  title={section.title}
                  expandedSubsections={expandedSubsections}
                  notificationPreferences={notificationPreferences}
                  handleSubsectionExpand={handleSubsectionExpand}
                  notificationTypes={section.types as NotificationType[]}
                  updateNotificationPreference={updateNotificationPreference}
                />
              ))}
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default Profile
