import React, { useState, useEffect } from 'react'
import * as tf from '@tensorflow/tfjs'
import { 
  TextField, 
  Button, 
  Typography, 
  Grid, 
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper
} from '@mui/material'
import { LineChart } from '@mui/x-charts/LineChart'
import { PageTitle } from 'components/PageTitle'
import { loadTruckModel, preprocessInput, TruckData, validateInputData } from './modelUtils'

const chartColors = {
  buyPrice: '#2196f3', // Material-UI primary blue
  sellPrice: '#f50057', // Material-UI secondary pink
};

const OpticGrayBook = () => {
  const [mileage, setMileage] = useState('')
  const [buyPrice, setBuyPrice] = useState<string | null>(null)
  const [sellPrice, setSellPrice] = useState<string | null>(null)
  const [model, setModel] = useState<tf.LayersModel | null>(null)

  const [make, setMake] = useState('')
  const [vehicleModel, setVehicleModel] = useState('')
  const [size, setSize] = useState('')
  const [fuel, setFuel] = useState('')
  const [year, setYear] = useState('')
  const [priceHistory, setPriceHistory] = useState<{ mileage: number; buyPrice: number; sellPrice: number }[]>([])

  const makeOptions = ['FREIGHTLINER', 'FORD', 'CHEVROLET', 'INTERNATIONAL']
  const modelOptions = ['MT45', 'MT55', 'P700', 'P1000']
  const sizeOptions = ['P700', 'P1000', 'P1200']
  const fuelOptions = ['Gas', 'Diesel']

  useEffect(() => {
    const initModel = async () => {
      const loadedModel = await loadTruckModel()
      if (loadedModel) {
        setModel(loadedModel)
        console.log('Model loaded successfully')
      }
    }
    initModel()
  }, [])

  const calculatePriceRange = async (truckData: TruckData) => {
    if (!model) return;

    const history = [];
    const mileageStep = 5000; // Calculate every 5k miles

    // Calculate for mileage range from 0 to 100k
    for (let mileage = 0; mileage <= 100000; mileage += mileageStep) {
      const mileageData = {
        ...truckData,
        Mileage: mileage
      };

      const processedInput = preprocessInput(mileageData);
      const predictions = model.predict(processedInput) as tf.Tensor;
      const [predictedBuyPrice, predictedSellPrice] = await predictions.data();

      history.push({
        mileage,
        buyPrice: Number(predictedBuyPrice.toFixed(2)),
        sellPrice: Number(predictedSellPrice.toFixed(2))
      });

      processedInput.dispose();
      predictions.dispose();
    }

    setPriceHistory(history);
  };

  const calculatePrices = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (!model) {
      console.error('Model not loaded yet')
      return
    }

    try {
      const truckData: TruckData = {
        Make: make,
        Model: vehicleModel,
        Size: size,
        Fuel: fuel,
        Mileage: parseInt(mileage),
        Year: parseInt(year),
        Condition: 'Used',
        Location: 'CO',
        Who: 'CRAIGSLIST',
        Type: 'Step van',
      }

      if (!validateInputData(truckData)) {
        console.error('Invalid input data')
        return
      }

      const processedInput = preprocessInput(truckData)
      const predictions = model.predict(processedInput) as tf.Tensor
      const [predictedBuyPrice, predictedSellPrice] = await predictions.data()
      
      setBuyPrice(predictedBuyPrice.toFixed(2))
      setSellPrice(predictedSellPrice.toFixed(2))
      
      // Calculate price range after current prediction
      await calculatePriceRange(truckData);
      
      // Cleanup
      processedInput.dispose()
      predictions.dispose()
    } catch (error) {
      console.error('Prediction error:', error)
    }
  }

  return (
    <>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={14} sm={8} md={6} style={{ textAlign: 'center' }}>
          <form onSubmit={calculatePrices}>
            <PageTitle
              title='Optic Gray Book'
              subtitle='Calculate buy and sell prices based on vehicle specifications.'
              bulletPoints={[
                'Select vehicle make and model',
                'Enter vehicle specifications',
                'Input mileage and year',
                'Get estimated buy and sell prices',
              ]}
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth style={{ margin: '10px 0' }}>
                  <InputLabel>Make</InputLabel>
                  <Select
                    value={make}
                    label="Make"
                    onChange={(e) => setMake(e.target.value)}
                    required
                  >
                    {makeOptions.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={6}>
                <FormControl fullWidth style={{ margin: '10px 0' }}>
                  <InputLabel>Model</InputLabel>
                  <Select
                    value={vehicleModel}
                    label="Model"
                    onChange={(e) => setVehicleModel(e.target.value)}
                    required
                  >
                    {modelOptions.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth style={{ margin: '10px 0' }}>
                  <InputLabel>Size</InputLabel>
                  <Select
                    value={size}
                    label="Size"
                    onChange={(e) => setSize(e.target.value)}
                    required
                  >
                    {sizeOptions.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth style={{ margin: '10px 0' }}>
                  <InputLabel>Fuel Type</InputLabel>
                  <Select
                    value={fuel}
                    label="Fuel Type"
                    onChange={(e) => setFuel(e.target.value)}
                    required
                  >
                    {fuelOptions.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label='Year'
                  variant='outlined'
                  type='number'
                  value={year}
                  onChange={e => setYear(e.target.value)}
                  fullWidth
                  required
                  style={{ margin: '10px 0' }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label='Mileage'
                  variant='outlined'
                  type='number'
                  value={mileage}
                  onChange={e => {
                    const value = parseInt(e.target.value);
                    if (value >= 0 && value <= 100000) {
                      setMileage(e.target.value);
                    }
                  }}
                  inputProps={{
                    min: 0,
                    max: 100000,
                    step: 1000
                  }}
                  fullWidth
                  required
                  helperText="Enter mileage between 0 and 100,000"
                  style={{ margin: '10px 0' }}
                />
              </Grid>
            </Grid>

            <Button 
              type='submit' 
              variant='contained' 
              color='primary'
              style={{ margin: '20px 0' }}
              fullWidth
            >
              Calculate Prices
            </Button>

            {(buyPrice && sellPrice) && (
              <>
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                  <Typography variant="h6" gutterBottom>
                    Estimated Prices
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Buy Price
                      </Typography>
                      <Typography variant="h5" color="primary">
                        ${buyPrice}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Sell Price
                      </Typography>
                      <Typography variant="h5" color="secondary">
                        ${sellPrice}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>

                {priceHistory.length > 0 && (
                  <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h6" gutterBottom>
                      Price Trend
                    </Typography>
                    <LineChart
                      xAxis={[{ 
                        data: priceHistory.map(item => item.mileage),
                        label: 'Mileage',
                        tickLabelStyle: {
                          fill: '#666666'
                        },
                        valueFormatter: (value) => `${value.toLocaleString()} mi`,
                        scaleType: 'linear'
                      }]}
                      series={[
                        {
                          data: priceHistory.map(item => item.buyPrice),
                          label: 'Buy Price',
                          color: chartColors.buyPrice,
                          curve: "linear",
                          showMark: false,
                          valueFormatter: (value: number | null) => 
                            value ? `$${value.toLocaleString()}` : '',
                        },
                        {
                          data: priceHistory.map(item => item.sellPrice),
                          label: 'Sell Price',
                          color: chartColors.sellPrice,
                          curve: "linear",
                          showMark: false,
                          valueFormatter: (value: number | null) => 
                            value ? `$${value.toLocaleString()}` : '',
                        }
                      ]}
                      height={300}
                      margin={{ top: 30, right: 30, bottom: 30, left: 60 }}
                      slotProps={{
                        legend: {
                          direction: 'row',
                          position: { vertical: 'top', horizontal: 'middle' },
                          padding: 8,
                          itemMarkWidth: 10,
                          itemMarkHeight: 10,
                          markGap: 5,
                          itemGap: 20,
                        }
                      }}
                      sx={{
                        '.MuiLineElement-root': {
                          strokeWidth: '2px',
                        },
                        '.MuiMarkElement-root': {
                          stroke: '#fff',
                          scale: '0.6',
                          fill: 'currentColor',
                        },
                        '.MuiChartsLegend-mark': {
                          rx: '50%',
                        },
                      }}
                    />
                  </Paper>
                )}
              </>
            )}
          </form>
        </Grid>
      </Grid>
    </>
  )
}

export default OpticGrayBook
