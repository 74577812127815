import React from 'react';
import { Button, Box, Tooltip } from '@mui/material';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

interface ZoomControlsProps {
  onZoomIn: () => void;
  onZoomOut: () => void;
}

const ZoomControls: React.FC<ZoomControlsProps> = ({ onZoomIn, onZoomOut }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Tooltip title="Zoom Out">
        <Button variant="outlined" onClick={onZoomOut} startIcon={<ZoomOutIcon />}>
          -
        </Button>
      </Tooltip>
      <Tooltip title="Zoom In">
        <Button variant="outlined" onClick={onZoomIn} startIcon={<ZoomInIcon />}>
          +
        </Button>
      </Tooltip>
    </Box>
  );
};

export default ZoomControls;
