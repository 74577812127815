import { useQuery } from '@tanstack/react-query'
import { collection, getDocs } from 'firebase/firestore'
import { db } from 'core/config/firebase'
import { useAuth } from 'contexts/AuthContext'
import { UserInfoType } from 'core/types/users'

export const useUsers = () => {
  const { userInfo } = useAuth()

  const {
    data: users,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      if (userInfo?.user_type !== 'admin') {
        throw new Error('Unauthorized access')
      }

      const usersCollection = collection(db, 'users')
      const snapshot = await getDocs(usersCollection)
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as UserInfoType[]
    },
    enabled: userInfo?.user_type === 'admin',
    staleTime: 1000 * 60 * 5, // 5 minutes
  })

  return {
    users: users || [],
    isLoading,
    isError,
    error,
    refetchUsers: refetch,
  }
} 