import { storage } from 'core/config/firebase'
import { ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage'

export const moveFirebaseStorageFile = async (oldPath: string, newPath: string): Promise<string> => {
  try {
    // 1. Get the download URL of the file at the old location
    const oldFileRef = ref(storage, oldPath)
    const downloadURL = await getDownloadURL(oldFileRef)

    // 2. Fetch the file content
    const response = await fetch(downloadURL)
    const fileBlob = await response.blob()

    // 3. Upload the file to the new location
    const newFileRef = ref(storage, newPath)
    await uploadBytes(newFileRef, fileBlob)

    // 4. Delete the file from the old location
    await deleteObject(oldFileRef)

    // 5. Get and return the new download URL
    return await getDownloadURL(newFileRef)
  } catch (error) {
    console.error('Error moving file:', error)
    throw error
  }
}
