import { collection, getDocs } from '@firebase/firestore'
import { db } from 'core/config/firebase'

interface UserForAssignment {
  id: string
  name: string
  email: string
  company_name?: string
  dealership_name?: string
  user_type?: string
}

export const listUsersForAssignment = async (): Promise<UserForAssignment[]> => {
  const usersRef = collection(db, 'users')
  const snapshot = await getDocs(usersRef)
  const userList = snapshot.docs.map(doc => {
    const data = doc.data()
    return {
      id: doc.id,
      name: `${data.first_name || ''} ${data.last_name || ''}`.trim(),
      email: data.email || '',
      company_name: data.company_name,
      dealership_name: data.dealership_name,
      user_type: data.user_type
    }
  })
  return userList
} 