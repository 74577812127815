// NotificationSection.tsx
import React from 'react'
import { Box, Typography, FormControlLabel, Switch } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { NotificationPreferences, NotificationType } from 'core/types/users'

interface NotificationSectionProps {
  title: string
  expandedSubsections: Set<string>
  notificationPreferences: NotificationPreferences
  handleSubsectionExpand: (subsection: string) => void
  updateNotificationPreference: (updatedPreferences: NotificationPreferences) => void
  notificationTypes: NotificationType[]
}
const notificationDisplayMap: Record<NotificationType, string> = {
  inventory_sold_event: 'Truck sold',
  inventory_published_on_site_event: 'Truck published on Optic site',
  user_created_event: 'New user created'
}


const NotificationSection: React.FC<NotificationSectionProps> = ({
  title,
  expandedSubsections,
  notificationPreferences,
  handleSubsectionExpand,
  notificationTypes,
  updateNotificationPreference,
}) => {
  const sectionKey = title.toLowerCase().replace(' ', '_')

  const handleNotificationChange = (type: NotificationType, channel: 'email' | 'sms') => {
    const updatedPreferences = {
      ...notificationPreferences,
      [type]: {
        ...notificationPreferences[type],
        [channel]: !notificationPreferences[type][channel],
      },
    }
    updateNotificationPreference(updatedPreferences)
  }

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 2,
      }}
    >
      <Box 
        onClick={e => {
          e.stopPropagation()
          handleSubsectionExpand(sectionKey)
        }}
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <Typography variant='h6' color='text.secondary'>
          {title}
        </Typography>
        {expandedSubsections.has(sectionKey) ? <ExpandLess /> : <ExpandMore />}
      </Box>
      {expandedSubsections.has(sectionKey) && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, pl: 2 }}>
          {notificationTypes.map(type => (
            <Box key={type} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: 'background.paper' }}>
              <Typography>{notificationDisplayMap[type]}</Typography>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      size='small'
                      checked={notificationPreferences[type].email}
                      onChange={() => handleNotificationChange(type, 'email')}
                      disabled={false}
                    />
                  }
                  label='Email'
                />
                <FormControlLabel
                  control={
                    <Switch
                      size='small'
                      checked={notificationPreferences[type].sms}
                      onChange={() => handleNotificationChange(type, 'sms')}
                      disabled={true}
                    />
                  }
                  label='SMS'
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default NotificationSection
