import { Box, Grid } from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import ImportedVehicleCard from './components/Cards/ImportedVehicleCard'

import { ButtonGroup, Skeleton, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'contexts/snackBarContext'
import { moveFirebaseStorageFile } from 'core/api/inventory/firebaseStorageOperations'
import {
  generateTransferedOpticStockNumber,
  generateTransferedShieldStockNumber
} from 'core/api/inventory/generateStockNumber'
import { initialFilters, useInventory, VehicleFilters } from 'hooks/useInventory'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { LoadingInventory } from '../InventoryV3/components/LoadingInventory'
import VehicleSidebarFilter from '../InventoryV3/components/VehicleSidebarFilter'
import ImportedVehicleForm from './components/Forms/ImportedVehicleForm'

// TODO: improve performance by storing vehicles in array

interface InventoryImporterProps {
  inventoryCollection: string
  subCollection: {
    name: string
    parentDocId: string
  }
}

const InventoryImporter: React.FC<InventoryImporterProps> = ({ inventoryCollection, subCollection }) => {
  const { showSnackbar } = useSnackbar()
  const { userInfo } = useAuth()
  const isMobile = useIsMobile()

  const [filters, setFilters] = useState<VehicleFilters>(initialFilters)
  const [isCardViewLoading, setIsCardViewLoading] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true)

  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)

  const { vehicles, isLoading, deleteDocument, refetchVehicles } = useInventory(inventoryCollection, subCollection, {
    dontShowUpdates: true,
  })
  const { vehicles: opticVehicles, addDocument: addOpticInventory } = useInventory('master_inventory', undefined, {
    dontShowUpdates: true,
  })
  const { vehicles: shieldVehicles, addDocument: addShieldInventory } = useInventory('shield_inventory', undefined, {
    dontShowUpdates: true,
  })

  const visibleVehicles = useMemo(() => {
    if (!vehicles) return []

    const filtered = vehicles.filter(vehicle => {
      const meetsVinFilter = !filters.vin || (vehicle.vin || '').toLowerCase().includes(filters.vin.toLowerCase())
      const meetsStockFilter =
        !filters.stockNumber ||
        (vehicle.stock_number || '').toLowerCase().includes(filters.stockNumber.toLowerCase())
      const meetsTypeFilter = !filters.type || vehicle.type === filters.type
      const meetsSizeFilter = !filters.size || vehicle.size === filters.size
      const meetsFuelFilter = !filters.fuel || vehicle.fuel === filters.fuel
      const meetsMakeFilter =
        !filters.make || (vehicle.make || '').toLowerCase().includes(filters.make.toLowerCase())
      const meetsModelFilter =
        !filters.model || (vehicle.model || '').toLowerCase().includes(filters.model.toLowerCase())
      const meetsMilesFilter = Number(vehicle.miles) >= filters.miles[0] && Number(vehicle.miles) <= filters.miles[1]
      const meetsListPriceFilter =
        Number(vehicle.optic_list_price) >= filters.listPrice[0] &&
        Number(vehicle.optic_list_price) <= filters.listPrice[1]
      const meetsAskingPriceFilter =
        Number(vehicle.seller_asking_price) >= filters.askingPrice[0] &&
        Number(vehicle.seller_asking_price) <= filters.askingPrice[1]
      const meetsLocationFilter =
        !filters.location || (vehicle.location || '').toLowerCase().includes(filters.location.toLowerCase())
      const meetsAvailabilityFilter = !filters.isAvailable || vehicle.status === 'Available'

      return (
        meetsVinFilter &&
        meetsStockFilter &&
        meetsTypeFilter &&
        meetsSizeFilter &&
        meetsFuelFilter &&
        meetsMilesFilter &&
        meetsListPriceFilter &&
        meetsAskingPriceFilter &&
        meetsLocationFilter &&
        meetsAvailabilityFilter &&
        meetsMakeFilter &&
        meetsModelFilter
      )
    })

    return filtered.slice(0, 1000)
  }, [vehicles, filters])

  const handleClickVehicle = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
  }

  const handleUpdateFilters = useCallback((newFilters: VehicleFilters) => {
    setFilters(newFilters)
  }, [])

  const handleClearFilters = useCallback(() => {
    setFilters(initialFilters)
    // Set loading states
    setIsCardViewLoading(true)
    setIsInitialLoad(true)

    // Refetch and reset loading states after a delay
    refetchVehicles().then(() => {
      setTimeout(() => {
        setIsCardViewLoading(false)
        setIsInitialLoad(false)
      }, 500)
    })
  }, [refetchVehicles])

  useEffect(() => {
    setIsInitialPageLoad(true)
    const timer = setTimeout(() => {
      setIsInitialPageLoad(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  const uType = userInfo?.user_type

  useEffect(() => {
    setIsCardViewLoading(true)
    // Only show loading state briefly if we have data
    const timer = setTimeout(() => {
      if (vehicles) {
        setIsCardViewLoading(false)
        setIsInitialLoad(false)
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [vehicles])

  useEffect(() => {
    // Show loading state on mount
    setIsInitialPageLoad(true)

    // Hide loading state after a delay
    const timer = setTimeout(() => {
      setIsInitialPageLoad(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  const handleTransferVehicle = async (
    newVehicleData: Omit<Vehicle, 'id'>,
    destInventoryCollection: 'master_inventory' | 'shield_inventory',
  ) => {
    if (!selectedVehicle) return

    const existingOpticStockNumbers =
      (opticVehicles as Vehicle[]).filter(v => v?.stock_number).map(v => v?.stock_number) ?? []
    const existingShieldStockNumbers =
      (shieldVehicles as Vehicle[]).filter(v => v?.stock_number).map(v => v?.stock_number) ?? []

    const movePhoto = (
      oldDocId: string,
      newDocId: string,
      collectionName: 'master_inventory' | 'shield_inventory',
    ) => {
      moveFirebaseStorageFile(
        `${inventoryCollection}/${subCollection.parentDocId}/${subCollection.name}/${oldDocId}/thumbnail.jpeg`,
        `${collectionName}/${newDocId}/thumbnail.jpeg`,
      )
        .then(newURl => {
          console.log('moved photo succefully to', newURl)
        })
        .catch(error => {
          console.log('error moving photo', error)
        })
    }

    let newStockNumber: string

    if (destInventoryCollection === 'master_inventory') {
      if ((opticVehicles as Vehicle[]).map(v => v.vin).includes(selectedVehicle.vin)) return
      newStockNumber = generateTransferedOpticStockNumber(
        existingOpticStockNumbers,
        userInfo?.first_name || '',
        userInfo?.last_name || '',
      )
    } else {
      if ((shieldVehicles as Vehicle[]).map(v => v.vin).includes(selectedVehicle.vin)) return
      newStockNumber = generateTransferedShieldStockNumber(existingShieldStockNumbers)
    }

    const newVehicle = {
      ...newVehicleData,
      status: 'Available',
      stock_number: newStockNumber,
      date_added: new Date().toISOString(),
    }
    if (destInventoryCollection === 'master_inventory') {
      addOpticInventory(newVehicle, {
        onSuccess: vehicleId => movePhoto(selectedVehicle.id, vehicleId, 'master_inventory'),
      })
    } else {
      addShieldInventory(newVehicle, {
        onSuccess: vehicleId => movePhoto(selectedVehicle.id, vehicleId, 'shield_inventory'),
      })
    }

    deleteDocument(selectedVehicle.id)
    showSnackbar(
      `Vehicle transferred to ${destInventoryCollection === 'master_inventory' ? 'optic' : 'shield'} inventory`,
      'success',
    )
    setSelectedVehicle(null)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
      }}
    >
      {isInitialPageLoad ? (
        <LoadingInventory />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
              alignItems: 'center',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
            }}
          >
            <Typography variant='h6' sx={{ color: '#666666' }}>
              Inventory importer
            </Typography>

            <Stack direction={isMobile ? 'column' : 'row'} spacing={2} alignItems='center' justifyContent='center'>
              <Stack direction='row' spacing={2} alignItems='center'>
                <ButtonGroup variant='contained' sx={{ bgcolor: 'background.paper' }}>
                  {isMobile && (
                    <VehicleSidebarFilter
                      filters={filters}
                      onFilterChange={handleUpdateFilters}
                      onFilterClear={handleClearFilters}
                      isAdmin={uType === 'admin'}
                    />
                  )}
                </ButtonGroup>
              </Stack>
            </Stack>
          </Box>

          {!vehicles ? (
            <LoadingInventory />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flexGrow: 1,
                overflow: 'hidden',
                height: 'calc(100% - 80px)',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  width: isMobile ? '100%' : '300px',
                  flexShrink: 0,
                  position: 'sticky',
                  top: 24,
                  alignSelf: 'flex-start',
                }}
              >
                <VehicleSidebarFilter
                  filters={filters}
                  onFilterChange={handleUpdateFilters}
                  onFilterClear={handleClearFilters}
                  isAdmin={uType === 'admin'}
                />
              </Box>

              <Box sx={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    margin: 0,
                    width: '100%',
                  }}
                >
                  {isCardViewLoading || (isInitialLoad && !vehicles)
                    ? Array.from({ length: 12 }).map((_, index) => (
                        <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3}>
                          <Box sx={{ height: '300px', bgcolor: 'background.paper', p: 2 }}>
                            <Skeleton variant='rectangular' width='100%' height={160} />
                            <Skeleton variant='text' width='60%' />
                            <Skeleton variant='text' width='40%' />
                          </Box>
                        </Grid>
                      ))
                    : visibleVehicles?.map(vehicle => (
                        <Grid item key={vehicle.id} xs={12} sm={6} md={6} lg={4} xl={3}>
                          <ImportedVehicleCard
                            collectionName={inventoryCollection}
                            subCollection={subCollection}
                            vehicle={vehicle}
                            onClick={() => handleClickVehicle(vehicle)}
                            onDeleteClick={() => deleteDocument(vehicle.id)}
                            isAdmin={uType === 'admin'}
                          />
                        </Grid>
                      ))}
                </Grid>
              </Box>
            </Box>
          )}
          {selectedVehicle && (
            <ImportedVehicleForm
              open={!!selectedVehicle}
              vehicle={selectedVehicle}
              onTransferVehicle={handleTransferVehicle}
              onClose={() => setSelectedVehicle(null)}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default InventoryImporter
