import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { Box, Button, IconButton, Typography, Chip } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, ValueGetterParams, ICellRendererParams } from 'ag-grid-community'
import { PageTitle } from 'components/PageTitle'
import ViewPhotoSubmissionsDialog from './components/ViewPhotoSubmissionsDialog'
import TransporterPhotoPreviewDialog from './components/TransporterPhotoPreviewDialog'
import { 
  PhotoUpload as PhotoUploadModel, 
  PhotoUploadVehicle, 
  UserData, 
  VehicleWithUser 
} from 'core/types/photoUpload'
import { Add, History } from '@mui/icons-material'
import AssignUserDialog from '../InventoryManagement/components/Dialogs/AssignUserDialog'
import { removeUserFromVehicle } from 'core/api/inventory/removeUserFromVehicle'
import { useSnackbar } from 'contexts/snackBarContext'
import { collection, doc, onSnapshot, deleteDoc, getDocs, updateDoc } from 'firebase/firestore'
import { db } from 'core/config/firebase'
import { PhotoAlbumOutlined } from '@mui/icons-material'
import { theme } from 'theme'
import { assignUserToVehicle } from 'core/api/inventory/assignUserToVehicle'
import PhotoUploadDialog from '../InventoryManagement/components/Dialogs/PhotoUploadDialog'
import InitiatePhotoUploadDialog from './components/InitiatePhotoUploadDialog'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import { PhotoUploadHistory } from './components/PhotoUploadHistory'
import CreateUserDialog from './components/CreateUserDialog'
import { calculateTotalSubmissions } from 'core/api/submissionUtils'
import { fetchPhotoUploads } from 'core/api/photoUploadUtils'

const TransporterPhotoUpload = () => {
  const { showSnackbar } = useSnackbar()
  const [selectedUpload, setSelectedUpload] = useState<PhotoUploadModel | null>(null)
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false)
  const [assignUserDialogOpen, setAssignUserDialogOpen] = useState(false)
  const [selectedVehicleForAssignment, setSelectedVehicleForAssignment] = useState<string | null>(null)
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)
  const [isPhotoUploadDialogOpen, setIsPhotoUploadDialogOpen] = useState(false)
  const [selectedVehicleForUpload, setSelectedVehicleForUpload] = useState<Vehicle | null>(null)
  const [isInitiateDialogOpen, setIsInitiateDialogOpen] = useState(false)
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false)
  const [isTransporterDialogOpen, setIsTransporterDialogOpen] = useState(false)

  const [photoUploads, setPhotoUploads] = useState<PhotoUploadModel[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const [filter, setFilter] = useState<'transporter'>('transporter')

  const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useState(false)
// i am so sorry for this code, really just need to get it out the door
// if someone fixes all this and it still works i will give you $100 
  const handleUserCreationSuccess = (userData: {
    id: string
    email: string
    firstName: string
    lastName: string
    phoneNumber: string
  }) => {
    //todo show something
  }

  useEffect(() => {
    const unsubscribe = fetchPhotoUploads(setPhotoUploads, setIsLoading, setError)
    return unsubscribe
  }, [])



  const handleDelete = async (userId: string, vehicles: PhotoUploadVehicle[]) => {
    try {
      const userVehiclesRef = collection(db, `users/${userId}/vehicles`)
      const userVehiclesSnapshot = await getDocs(userVehiclesRef)
      const deletePromises = userVehiclesSnapshot.docs.map(doc => deleteDoc(doc.ref))
      await Promise.all(deletePromises)

      const updatePromises = vehicles.map(vehicle => {
        // idk why the fuck the id's get this suffix sometimes or what the fuck is happening 
        const vehicleId = vehicle.id.endsWith('_0') ? vehicle.id.slice(0, -2) : vehicle.id
        const vehicleRef = doc(db, `master_inventory/${vehicleId}`)
        return updateDoc(vehicleRef, { assigned_user: null })
      })
      await Promise.all(updatePromises)

      showSnackbar('Vehicles deleted and users unassigned successfully', 'success')
    } catch (error) {
      console.error('Error deleting vehicles or unassigning users:', error)
      showSnackbar('Error deleting vehicles or unassigning users', 'error')
    }
  }

  const ActionCellRenderer = (props: any) => (
    <Box sx={{ 
      display: 'grid',
      gridTemplateColumns: 'repeat(3, auto)',
      gap: 0.5,
      '& .MuiButton-root': {
        minWidth: 'fit-content',
        padding: '4px 8px',
        fontSize: '0.75rem'
      }
    }}>
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          setSelectedUpload(props.data)
          if (props.data.user_type === 'transporter') {
            setIsTransporterDialogOpen(true)
          } else {
            setIsViewDialogOpen(true)
          }
        }}
        disabled={!props.data.vehicles?.length}
        sx={{ whiteSpace: 'nowrap' }}
      >
        View Photos
      </Button>
      <IconButton
        aria-label="Generate Photo Upload Link"
        onClick={() => {
          setSelectedVehicleForUpload({
            ...props.data.vehicles[0],
            assigned_user: {
              id: props.data.id,
              email: props.data.email,
              user_type: props.data.user_type || 'default'
            },
            allVehicles: props.data.vehicles
          })
          setIsPhotoUploadDialogOpen(true)
        }}
        disabled={!props.data.email || !props.data.vehicles?.length}
        sx={{ 
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          padding: '4px',
          width: '28px',
          height: '28px',
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          }
        }}
      >
        <PhotoAlbumOutlined sx={{ fontSize: '1rem' }} />
      </IconButton>
      {/* <Button
        variant="outlined"
        size="small"
        color="error"
        onClick={() => handleDelete(props.data.id, props.data.vehicles)}
        sx={{ whiteSpace: 'nowrap' }}
      >
        Delete
      </Button> */}
    </Box>
  )

  const UserTypeChipRenderer = (props: any) => {
    let chipColor = theme.palette.primary.main;
    if (props.value === 'transporter') {
      chipColor = 'purple';
    } else if (props.value === 'admin') {
      chipColor = 'red';
    }

    return (
      <Chip
        label={props.value.charAt(0).toUpperCase() + props.value.slice(1)}
        sx={{
          backgroundColor: chipColor,
          color: 'white',
        }}
        size="small"
      />
    )
  }

  const columnDefs = useMemo<ColDef[]>(() => {
    const baseColumns: ColDef[] = [
      { 
        field: 'email',
        headerName: 'Email',
        width: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: 'agGroupCellRenderer'
      },
      { 
        field: 'phone',
        headerName: 'Phone',
        width: 150,
        filter: 'agTextColumnFilter'
      },
      { 
        field: 'status',
        headerName: 'Status',
        width: 130,
        filter: 'agTextColumnFilter',
        cellRenderer: (params: any) => {
          const chipStyles = {
            borderRadius: '12px',
            padding: '2px 8px',
            display: 'inline-flex',
            alignItems: 'center',
            fontSize: '0.75rem',
            fontWeight: 500,
            lineHeight: '1.5',
            whiteSpace: 'nowrap'
          }

          const preDelivery = params.data.preDeliverySubmissions || 0;
          const postDelivery = params.data.postDeliverySubmissions || 0;

          let status = 'Not submitted';
          if (preDelivery > 0 && postDelivery > 0) {
            status = 'Fully submitted';
          } else if (preDelivery > 0 || postDelivery > 0) {
            status = 'Partially submitted';
          }

          const statusColor = status === 'Fully submitted' ? 'success.main' : 
                              status === 'Partially submitted' ? 'warning.main' : 
                              'error.main';

          return (
            <Box
              sx={{
                ...chipStyles,
                backgroundColor: statusColor,
                color: 'white',
              }}
            >
              {status}
            </Box>
          )
        }
      },
      {
        field: 'vehicles',
        headerName: 'Vehicle Count',
        width: 130,
        valueGetter: (params: ValueGetterParams) => params.data?.vehicles?.length || 0,
        filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Actions',
        width: 150,
        cellRenderer: ActionCellRenderer,
        sortable: false,
        filter: false
      }
    ];

    baseColumns.splice(2, 0, 
      { 
        field: 'preDeliverySubmissions',
        headerName: 'Pre-Delivery Submissions',
        width: 180,
        filter: 'agNumberColumnFilter'
      },
      { 
        field: 'postDeliverySubmissions',
        headerName: 'Post-Delivery Submissions',
        width: 180,
        filter: 'agNumberColumnFilter'
      }
    );

    return baseColumns;
  }, [filter]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    flex: 1,
  }), [])

  const chipStyles = {
    borderRadius: '12px',
    padding: '2px 8px',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.5',
    whiteSpace: 'nowrap'
  }

  const StatusChip = ({ status }: { status: string }) => (
    <Box
      sx={{
        ...chipStyles,
        backgroundColor: status === 'Submitted' ? 'success.main' : 'error.main',
        color: 'white',
      }}
    >
      {status}
    </Box>
  );

  const detailCellRenderer = useMemo(() => {
    return (params: ICellRendererParams) => {
      const detailGridOptions = {
        columnDefs: [
          { field: 'make', headerName: 'Make' },
          { field: 'model', headerName: 'Model' },
          { field: 'year', headerName: 'Year' },
          { field: 'vin', headerName: 'VIN' },
          { 
            field: 'status', 
            headerName: 'Status',
            cellRenderer: (params: any) => <StatusChip status={params.value} />,
            width: 130,
          }
        ],
        defaultColDef: {
          flex: 1,
          sortable: true,
        },
        rowData: params.data.vehicles,
        domLayout: 'autoHeight' as const,
        suppressHorizontalScroll: true,
      }

      return (
        <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
          <AgGridReact {...detailGridOptions} />
        </div>
      )
    }
  }, [])

  const isRowMaster = useCallback((dataItem: any) => {
    return dataItem ? dataItem.vehicles?.length > 0 : false
  }, [])

  const handleAssignUser = async (userId: string) => {
    if (selectedVehicleForAssignment) {
      try {
        await assignUserToVehicle(selectedVehicleForAssignment, userId, 'master_inventory')
        showSnackbar('User assigned successfully', 'success')
        setAssignUserDialogOpen(false)
        setSelectedVehicleForAssignment(null)
      } catch (error) {
        showSnackbar('Error assigning user', 'error')
      }
    }
  }

  const handleRemoveUser = async (userId: string) => {
    if (selectedVehicleForAssignment) {
      try {
        await removeUserFromVehicle(selectedVehicleForAssignment, userId, 'master_inventory')
        showSnackbar('User removed successfully', 'success')
      } catch (error) {
        showSnackbar('Error removing user', 'error')
      }
    }
  }

  const filteredUploads = photoUploads.filter(upload => upload.user_type === filter)


  return (
    <>
      <PageTitle
        title="Transporter Photo Uploader"
        subtitle="Manage transporter photo upload links and submissions"
        bulletPoints={[
          'View all transporters with vehicles requiring photos',
          'Track photo submission status for each vehicle',
          'View submitted photos and vehicle details'
        ]}
        action={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="outlined"
              startIcon={<History />}
              onClick={() => setIsHistoryDialogOpen(true)}
            >
              HISTORY
            </Button>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setIsInitiateDialogOpen(true)}
            >
              NEW PHOTO UPLOAD
            </Button>
          </Box>
        }
      />

      {error ? (
        <Box sx={{ p: 4, textAlign: 'center', color: 'error.main' }}>
          Error loading data: {(error as Error).message}
        </Box>
      ) : isLoading ? (
        <Box sx={{ p: 4, textAlign: 'center' }}>Loading...</Box>
      ) : (
        <Box className="ag-theme-alpine" sx={{ height: 600, width: '100%' }}>
          <AgGridReact
            rowData={filteredUploads}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={10}
            suppressCellFocus={true}
            masterDetail={true}
            detailCellRenderer={detailCellRenderer}
            isRowMaster={isRowMaster}
            detailRowHeight={250}
            overlayNoRowsTemplate="<span style='padding: 10px'>No photo uploads found</span>"
            overlayLoadingTemplate="<span style='padding: 10px'>Loading...</span>"
            loading={isLoading}
          />
        </Box>
      )}

      {selectedUpload && selectedUpload.user_type === 'transporter' && (
        <TransporterPhotoPreviewDialog
          open={isTransporterDialogOpen}
          onClose={() => {
            setIsTransporterDialogOpen(false)
            setSelectedUpload(null)
          }}
          vehicles={selectedUpload.vehicles}
          userId={selectedUpload.id}
          phoneNumber={selectedUpload.phone}
        />
      )}

      {selectedUpload && selectedUpload.user_type !== 'transporter' && (
        <ViewPhotoSubmissionsDialog
          open={isViewDialogOpen}
          onClose={() => {
            setIsViewDialogOpen(false)
            setSelectedUpload(null)
          }}
          vehicles={selectedUpload.vehicles}
          userId={selectedUpload.id}
        />
      )}

      <AssignUserDialog
        open={assignUserDialogOpen}
        onClose={() => {
          setAssignUserDialogOpen(false)
          setSelectedVehicleForAssignment(null)
        }}
        onAssign={handleAssignUser}
        onRemove={handleRemoveUser}
        currentUserId={selectedVehicleForAssignment ? photoUploads?.find(
          upload => upload.vehicles?.some((v) => v.id === selectedVehicleForAssignment)
        )?.vehicles?.find((v: PhotoUploadVehicle) => v.id === selectedVehicleForAssignment)?.assigned_user?.id : undefined}
        vehicleId={selectedVehicleForAssignment || ''}
        collectionName="master_inventory"
      />

      {selectedVehicleForUpload && (
        <PhotoUploadDialog
          open={isPhotoUploadDialogOpen}
          onClose={() => {
            setIsPhotoUploadDialogOpen(false)
            setSelectedVehicleForUpload(null)
          }}
          //@ts-ignore
          vehicle={selectedVehicleForUpload}
        />
      )}

      <InitiatePhotoUploadDialog
        open={isInitiateDialogOpen}
        onClose={() => setIsInitiateDialogOpen(false)}
        transporterOnly={true}
      />

      <PhotoUploadHistory
        open={isHistoryDialogOpen}
        onClose={() => setIsHistoryDialogOpen(false)}
      />

      <CreateUserDialog
        open={isCreateUserDialogOpen}
        onClose={() => setIsCreateUserDialogOpen(false)}
        onSuccess={handleUserCreationSuccess}
      />
    </>
  )
}

export default TransporterPhotoUpload 