import { doc, getDoc, updateDoc, setDoc, collection } from 'firebase/firestore'
import { db } from '../../config/firebase'
import { useAuth } from 'contexts/AuthContext'
import { getUserProfile } from '../users'

export const assignUserToVehicle = async (
  vehicleId: string, 
  userId: string, 
  collectionName: string
) => {
  try {
    // Get user data using getUserProfile
    console.log('Getting user data for:', userId)
    const userInfo = await getUserProfile(userId)
    if (!userInfo) {
      throw new Error('User not found')
    }

    // Get vehicle data
    const vehicleRef = doc(db, collectionName, vehicleId)
    const vehicleSnap = await getDoc(vehicleRef)
    const vehicleData = vehicleSnap.data()

    if (!vehicleData) {
      throw new Error('Vehicle not found')
    }

    const userName = `${userInfo.first_name || ''} ${userInfo.last_name || ''}`.trim()

    // Create a reference in the user's vehicles subcollection
    const userVehicleRef = doc(collection(db, 'users', userId, 'vehicles'), vehicleId)
    await setDoc(userVehicleRef, {
      vehicleId,
      assignedAt: new Date().toISOString(),
      accepted: false,
      vehicleInfo: {
        year: vehicleData.year || '',
        make: vehicleData.make || '',
        model: vehicleData.model || '',
        vin: vehicleData.vin || '',
        stock_number: vehicleData.stock_number || ''
      }
    }, { merge: true })

    // Update vehicle with current user data
    await updateDoc(vehicleRef, {
      assigned_user: {
        id: userId,
        name: userName
      }
    })
  } catch (error) {
    console.error('Error assigning user to vehicle:', error)
    throw error
  }
} 