import * as tf from '@tensorflow/tfjs'

// Define the model interface
export interface TruckData {
  Make: string
  Model: string
  Size: string
  Fuel: string
  Mileage: number
  Year: number
  Condition: string
  Location: string
  Who: string
  Type: string
}

// Create a function to load the model
export const loadTruckModel = async () => {
  try {
    // First load the model architecture
    await tf.ready()
    const model = await tf.loadLayersModel('http://localhost:3000/models/model.json')
    return model
  } catch (error) {
    console.error('Error loading model:', error)
    return null
  }
}

// Create a function to preprocess the input
export const preprocessInput = (data: TruckData) => {
  // Initialize array with 254 zeros
  const features = new Array(254).fill(0)

  // One-hot encoding for Make (positions 0-3)
  const makeIndex =
    {
      FREIGHTLINER: 0,
      FORD: 1,
      CHEVROLET: 2,
      INTERNATIONAL: 3,
    }[data.Make] || 0
  features[makeIndex] = 1

  // One-hot encoding for Model (positions 4-7)
  const modelIndex =
    {
      MT45: 4,
      MT55: 5,
      P700: 6,
      P1000: 7,
    }[data.Model] || 4
  features[modelIndex] = 1

  // One-hot encoding for Size (positions 8-10)
  const sizeIndex =
    {
      P700: 8,
      P1000: 9,
      P1200: 10,
    }[data.Size] || 8
  features[sizeIndex] = 1

  // One-hot encoding for Fuel (positions 11-12)
  const fuelIndex =
    {
      Gas: 11,
      Diesel: 12,
    }[data.Fuel] || 11
  features[fuelIndex] = 1

  // Numerical features (normalized)
  features[13] = Math.min(1, data.Mileage / 1000000) // Normalize mileage to [0,1] range using 1M as max
  features[14] = (data.Year - 1900) / (2024 - 1900) // Normalize year to [0,1] range

  // Additional features
  features[15] = data.Condition === 'Used' ? 1 : 0
  features[16] = data.Location === 'CO' ? 1 : 0
  features[17] = data.Who === 'CRAIGSLIST' ? 1 : 0
  features[18] = data.Type === 'Step van' ? 1 : 0

  // The remaining positions (19-253) stay as zeros
  // These might be used for additional features in the model

  // Convert to tensor and reshape for model input
  return tf.tensor2d([features], [1, 254])
}

// Validate input data
export const validateInputData = (data: TruckData): boolean => {
  // Check if all required fields are present and not empty
  if (!data.Make || !data.Model || !data.Size || !data.Fuel) {
    console.error('Missing required fields')
    return false
  }

  // Validate year
  const currentYear = new Date().getFullYear()
  if (isNaN(data.Year) || data.Year < 1900 || data.Year > currentYear) {
    console.error('Invalid year')
    return false
  }

  // Validate mileage
  if (isNaN(data.Mileage) || data.Mileage < 0) {
    console.error('Invalid mileage: Must be a positive number')
    return false
  }

  // Validate make
  const validMakes = ['FREIGHTLINER', 'FORD', 'CHEVROLET', 'INTERNATIONAL']
  if (!validMakes.includes(data.Make)) {
    console.error('Invalid make')
    return false
  }

  // Validate model
  const validModels = ['MT45', 'MT55', 'P700', 'P1000']
  if (!validModels.includes(data.Model)) {
    console.error('Invalid model')
    return false
  }

  // Validate size
  const validSizes = ['P700', 'P1000', 'P1200']
  if (!validSizes.includes(data.Size)) {
    console.error('Invalid size')
    return false
  }

  // Validate fuel type
  const validFuelTypes = ['Gas', 'Diesel']
  if (!validFuelTypes.includes(data.Fuel)) {
    console.error('Invalid fuel type')
    return false
  }

  // All validations passed
  return true
}
